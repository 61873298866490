import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';

class LocationForm extends Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.onSubmit({
          ...values,
          id: this.props.defaultData.id,
        });
        this.props.form.resetFields();
      }
    });
  };

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item label="Location Code" hasFeedback>
          {getFieldDecorator('code', {
            initialValue: this.props.defaultData.code,
            rules: [
              {
                required: true,
                message: 'Please input the Location Code!',
              },
            ],
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Location Description" hasFeedback>
          {getFieldDecorator('description', {
            initialValue: this.props.defaultData.description,
            rules: [
              {
                required: true,
                message: 'Please input the Location Description!',
              },
            ],
          })(<Input.TextArea rows={4} />)}
        </Form.Item>

        <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="white" onClick={this.onCancel}>
            Cancel
          </Button>
          <Button
            loading={this.props.saving}
            style={{ marginLeft: 6 }}
            icon="save"
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

LocationForm.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  saving: PropTypes.bool,
  defaultData: PropTypes.object,
};

LocationForm.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  saving: false,
  defaultData: {},
};

const WrappedLocationForm = Form.create({ name: 'locationForm' })(LocationForm);

export default WrappedLocationForm;