import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { observe } from 'mobx';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import {
  CurrentUserStore,
  ShopStore,
  CarStore,
  AppStore,
  TableStore,
} from 'stores';
import { UserRoles } from 'stores/Classes/UserObject';
import HeaderMessage from '../Report/HeaderMessage';

import { DealershipChooser } from 'components';
import { Row, Col, Typography, Button, notification, Icon, Spin } from 'antd';

import AssetTypeChooser from 'components/Vehicles/AssetTypeChooser';
import { first, isNil, set } from 'lodash';
import { Logger } from 'stores/Classes';
import VehicleNoteTextarea from 'components/Vehicles/VehicleNoteTextarea';
import { renderVehicleId } from 'containers/CarListTable/CarListTableColumns';
import RestoreAssetModal from 'components/Modals/RestoreAssetModal';
import TableContainer from 'components/Tables/TableContainer';
import LoadingWrapper from 'components/LoadingWrapper';

const Wrapper = styled.div`
  position: relative;
`;

const { Title } = Typography;

class ArchivedAssetsPage extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  componentDidMount() {
    AppStore.setSelectedSidebarKey('/archived-assets');

    if (CurrentUserStore.user.role !== UserRoles.customer) {
      if (!ShopStore.loaded && !ShopStore.pending) {
        ShopStore.getShops();
      }
    }

    this.init();
  }

  state = {
    archiveShopId: null,
    archivedAssets: [],
    initialLoading: false,
    isLoadingAssets: false,
    loadingAssetsCount: false,
    currentType: 'vehicles',
    assetsCountSummary: {
      countVehicles: 0,
      countTrailers: 0,
    },
    paginationMeta: {
      total: 0,
      current: 1,
    },
    selectedAssets: [],
    isRestoreAssetsModalVisible: false,
    isRestoringAssets: false,
  };

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    this.init();
  });

  vechilesTableStore = new TableStore();
  trailersTableStore = new TableStore();

  archivedAssetsColumns = [
    {
      key: 'car_name',
      onCell: () => ({
        className: 'hover-effect-cell',
      }),
      title: 'Unit ID',
      sorter: false,
      render: renderVehicleId,
    },
    {
      key: 'archived_by',
      onCell: () => ({
        className: 'hover-effect-cell',
      }),
      title: 'Archived By',
      sorter: false,
      render: (unused, row) => {
        return row.extraDetails?.archive?.archivedBy || 'Unknown';
      },
    },
    {
      key: 'archived_on',
      onCell: () => ({
        className: 'hover-effect-cell',
      }),
      title: 'Archived On',
      sorter: false,
      render: (unused, row) => {
        const userTimezone =
          CurrentUserStore?.user?.settings?.timezone || 'America/Toronto';
        return row.extraDetails?.archive?.archiveDate
          ? moment(row.extraDetails?.archive?.archiveDate)
              .tz(userTimezone)
              .format('lll')
          : 'Unknown';
      },
    },
    {
      field: 'notes',
      key: 'notes',
      filter: false,
      onCell: () => ({
        className: 'hover-effect-cell',
      }),
      align: 'center',
      title: (
        <div>
          <span>Notes</span>
        </div>
      ),
      sorter: false,
      render: (car) => {
        return (
          <VehicleNoteTextarea
            car={car}
            onSave={({ id, notes }) => {
              this.saveCarNotes({ id, notes });
            }}
          />
        );
      },
      rowClick: false,
    },
  ];

  vehicleRowSelection = {
    onChange: (selectedRowKeys) => {
      this.setState({
        selectedAssets: selectedRowKeys,
      });
    },
    getCheckboxProps: (record) => ({
      // disabled: record.name === 'Disabled User', // Column configuration not to be checked
      // name: record.name,
    }),
  };

  componentWillUnmount() {
    this.disposer();
  }

  loadArchiveShopTotalAssetsCount = async () => {
    try {
      this.setState({ loadingAssetsCount: true });
      const assetsCountSummary = await CarStore.getShopAssetsCountSummary(
        this.state.archiveShopId
      );
      this.setState({ assetsCountSummary: first(assetsCountSummary) || {} });
    } catch (error) {
      Logger.error(error);
      AppStore.addError(error.message || 'Failed to load assets count');
    } finally {
      this.setState({ loadingAssetsCount: false });
    }
  };

  saveCarNotes = async ({ id, notes }) => {
    try {
      await CarStore.updateCarNotes(id, notes);
    } catch (error) {
      AppStore.addError('Failed to save notes');
    }
  };

  handleRestoreAssets = async (notes) => {
    try {
      this.setState({ isRestoringAssets: true });
      await CarStore.restoreAssets({
        assetIds: this.state.selectedAssets,
        notes,
        shopId: this.state.archiveShopId,
      });
      this.setState(
        {
          isRestoreAssetsModalVisible: false,
          selectedAssets: [],
        },
        async () => {
          notification.success({
            message: 'Asset(s) Restored',
            description: 'Assets restored successfully',
            icon: <Icon type="check-circle" style={{ color: 'green' }} />,
          });
          await this.loadTotalAssetsCountAndArchivedShopAssets();
        }
      );
    } catch (error) {
      Logger.error(error);
      AppStore.addError(error.message || 'Failed to restore assets');
    } finally {
      this.setState({ isRestoringAssets: false });
    }
  };

  /**
   *
   * @param {string} type one of 'Vehicles' or 'Trailers'
   * @param {*} filters
   * @returns
   */
  loadArchivedShopAssetsFromType = async (type = 'vehicle', filters) => {
    try {
      if (!this.state.archiveShopId) {
        return;
      }
      this.setState({ isLoadingAssets: true });
      if (isNil(filters)) {
        filters = {
          offset: 0,
          limit: 10,
          sort: null,
          filter: {},
        };
      }

      set(filters, 'filter.exclude', 'issueList');
      set(
        filters,
        'filter.car_type',
        type === 'vehicle' || type === 'vehicles' ? 'Vehicle' : 'Trailer'
      );

      let response;

      const tableStore =
        type === 'vehicle' || type === 'vehicles'
          ? this.vechilesTableStore
          : this.trailersTableStore;

      if (CurrentUserStore.user.role === 'customer') {
        response = await CarStore.getUserCarsForTable(
          filters,
          tableStore,
          CurrentUserStore.user.id,
          this.state.archiveShopId,
          {
            isAddNextPmSummary: false,
            isAddWorkOrder: true,
            isAddEngineLightStatus: false,
            isAddDvirData: false,
            isReturnMetaAndData: true,
          }
        );
      } else {
        response = await CarStore.getShopCarsForTable(
          filters,
          tableStore,
          this.state.archiveShopId,
          {
            isAddNextPmSummary: false,
            isAddWorkOrder: true,
            isAddEngineLightStatus: false,
            isAddDvirData: false,
            isReturnMetaAndData: true,
          }
        );
      }

      if (!response) {
        return;
      }

      this.setState({
        archivedAssets: response.data.map((car) => ({
          ...car,
          key: car.id,
        })),
        paginationMeta: {
          ...this.state.paginationMeta,
          total: response.meta.count,
          current: filters.offset / 10 + 1,
        },
      });
    } catch (error) {
      Logger.error(error);
      AppStore.addError(error.message || 'Failed to load archived assets');
    } finally {
      this.setState({ isLoadingAssets: false });
    }
  };

  loadTotalAssetsCountAndArchivedShopAssets = async () => {
    this.loadArchiveShopTotalAssetsCount();
    await this.loadArchivedShopAssetsFromType(this.state.currentType, {
      offset: 0,
      limit: 10,
    });
  };

  async init() {
    const shopId = ShopStore.currentShop.id;
    try {
      this.setState({ initialLoading: true });
      const archiveShopId = await ShopStore.getShopArchiveShopId(shopId);
      if (archiveShopId) {
        // the shop has an archive shop
        return this.setState(
          {
            archiveShopId,
          },
          async () => {
            await this.loadTotalAssetsCountAndArchivedShopAssets();
          }
        );
      }
      this.setState({
        archivedAssets: [],
        archiveShopId: null,
      });
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ initialLoading: false });
    }
  }

  addFiltersToQuery = (type = '', value) => {
    if (!type) {
      throw new Error('Type is required');
    }
    const queryParams = new URLSearchParams(this.props.history.location.search);
    queryParams.set(type, value);
    this.props.history.push({
      search: queryParams.toString(),
    });
  };

  render() {
    if (this.state.initialLoading) {
      return (
        <LoadingWrapper>
          <Spin />
        </LoadingWrapper>
      );
    }

    return (
      <Wrapper>
        <Row>
          <Col span={24}>
            <Title>Archived Assets</Title>
          </Col>
        </Row>
        <Row>
          <Col span={16}>
            <DealershipChooser />
            <HeaderMessage />
          </Col>
        </Row>
        <Row
          style={{
            marginTop: '1rem',
          }}
        >
          <Col span={16}>
            <AssetTypeChooser
              disabled={this.state.isLoadingAssets}
              currentType={this.state.currentType}
              onChange={(type) => {
                this.setState({ currentType: type }, async () => {
                  // set the current type to query param
                  this.addFiltersToQuery('currentType', type);
                  await this.loadArchivedShopAssetsFromType(
                    this.state.currentType,
                    {
                      offset: 0,
                      limit: 10,
                    }
                  );
                });
              }}
              loading={this.state.loadingAssetsCount}
              vehiclesCount={this.state.assetsCountSummary?.countVehicles || 0}
              trailersCount={this.state.assetsCountSummary?.countTrailers || 0}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }} gutter={[16, 16]}>
          <Col span={24}>
            <Button
              type="primary"
              data-test="restore-assets-button"
              size="small"
              disabled={this.state.selectedAssets.length === 0}
              style={{ marginRight: 10 }}
              onClick={() => {
                this.setState({
                  isRestoreAssetsModalVisible: true,
                });
              }}
            >
              Restore Asset(s)
            </Button>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <TableContainer
              data-test="archived-assets-table"
              columns={this.archivedAssetsColumns}
              dataSource={this.state.archivedAssets}
              loading={this.state.isLoadingAssets}
              rowSelection={this.vehicleRowSelection}
              pagination={{
                total: this.state.paginationMeta.total,
                current: this.state.paginationMeta.current,
                onChange: async (page) => {
                  await this.loadArchivedShopAssetsFromType(
                    this.state.currentType,
                    {
                      offset: (page - 1) * 10,
                      limit: 10,
                    }
                  );
                },
              }}
            />
          </Col>
        </Row>

        <RestoreAssetModal
          visible={this.state.isRestoreAssetsModalVisible}
          isRestoring={this.state.isRestoringAssets}
          onCancel={() => {
            this.setState({ isRestoreAssetsModalVisible: false });
          }}
          onOk={(notes) => {
            this.handleRestoreAssets(notes);
          }}
        />
      </Wrapper>
    );
  }
}

export default withRouter(observer(ArchivedAssetsPage));
