import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';

import {
  GroupedBarChart,
  GroupedBarChartNoLabel,
} from 'shared/PitstopUI/PitstopChart';
import { DealershipChooser } from 'components';
import { WidgetToolTip } from 'shared/PitstopUI/PitstopChart';

import ScatterDataAfterBreakdown from './ScatterDataAfterBreakdown';
import ScatterDataBeforeBreakdown from './scatterDataBeforeBreakdown';

import RepeatedRepairPoints from './RepeatedRepairPoints';
import RepeatedRepairCrosses from './RepeatedRepairCrosses';

import PMServiceVisitTimesBlue from './PMServiceVisitTimesBlue';
import PMServiceVisitTimesRed from './PMServiceVisitTimesRed';
import { Col, Row } from 'antd';

import QualityOfPMAnalysis from 'assets/demo-charts/2. Quality of PM Analysis.png';
import VehicleRepeatVisits from 'assets/demo-charts/3. Vehicle Repeat Visits.png';
import { AppStore } from 'stores';
// import VehicleCosts from 'assets/demo-charts/4. Vehicle Costs.png';
// import AverageCostAmountPerVisit from 'assets/demo-charts/5. Average Cost Amount Per Visit.png';

const FullWidthImage = styled.img`
  width: 100%;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const WidgetContainer = styled.div`
  border: solid;
  border-width: 2px;
  border-color: #f9f9f9;
  margin: 5px;
  padding: 12px;
`;

const vehicleRepairCountData = {
  yAxis: {
    labels: {
      enabled: true,
      format: '{value}',
      style: {
        color: 'black',
      },
    },
    title: {
      text: 'Number of Vehicles',
      style: {
        color: 'black',
      },
    },
  },
  xAxis: {
    categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    title: 'Number of repairs',
  },
  series: [
    { name: 'Number of Repairs', data: [49, 18, 5, 4, 1, 2, 4, 2, 1, 4, 0, 1] },
  ],
};

const vehicleRepeatedRepairData = {
  yAxis: {
    labels: {
      enabled: true,
      format: '{value}',
      style: {
        color: 'black',
      },
    },
    title: {
      text: 'Number of Vehicles',
      style: {
        color: 'black',
      },
    },
  },
  xAxis: {
    categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    title: 'Number of Repeated Repairs',
  },
  series: [
    {
      name: 'Number of Repeated Repairs',
      data: [11, 3, 4, 4, 0, 0, 0, 0, 1, 0],
    },
  ],
};

/*const pmVisitsBeforeRepairData = {
  yAxis:{
    labels: {
      enabled: true,
      format: '{value}',
      style: {
        color: 'black'
      }
    },
    title: {
      text: 'Number of PM Visits',
      style: {
        color: 'black'
      }
    },
  },
  xAxis: {categories:['1 to 5 Days', '6 to 10 Days', '11 to 15 Days',' More Than 15 Days']},
  series: [{name: 'Number of Days Before a Repair', data: [22, 25, 18, 14]}]
};

const pmVisitsAfterRepairData = {
  yAxis:{
    labels: {
      enabled: true,
      format: '{value}',
      style: {
        color: 'black'
      }
    },
    title: {
      text: 'Number of PM Visits',
      style: {
        color: 'black'
      }
    },
  },
  xAxis: {categories:['1 to 5 Days', '6 to 10 Days', '11 to 15 Days',' More Than 15 Days']},
  series: [{name: 'Number of Days After a Repair', data: [5, 0, 1, 1]}]
};*/

const allPMVisits = {
  yAxis: {
    labels: {
      enabled: true,
      format: '{value}',
      style: {
        color: 'black',
      },
    },
    title: {
      text: 'Number of PM Visits',
      style: {
        color: 'black',
      },
    },
  },
  xAxis: {
    plotLines: [{ color: '#000000', width: 2, value: 3.5 }],
    categories: [
      ' More Than 15 Days',
      '11 to 15 Days',
      '6 to 10 Days',
      '1 to 5 Days',
      '1 to 5 Days',
      '6 to 10 Days',
      '11 to 15 Days',
      ' More Than 15 Days',
    ],
  },
  series: [
    {
      name: 'Number of Days Before a Repair',
      data: [22, 25, 18, 14, 0, 0, 0, 0],
      color: 'red',
    },
    {
      name: 'Number of Days After a Repair',
      data: [0, 0, 0, 0, 5, 0, 1, 1],
      color: 'blue',
    },
  ],
};

const scatterOptions = {
  tooltip: {
    enabled: false,
  },
  title: null,
  chart: {
    type: 'scatter',
    zoomType: 'xy',
    animation: false,
  },
  xAxis: {
    title: {
      enabled: true,
      text: 'Days Before/After Breakdown',
    },
    min: -21,
    max: 21,
    scrollbar: {
      enabled: true,
      showFull: false,
      liveRedraw: true,
    },
  },

  yAxis: {
    min: 0,
    max: 300,
    title: {
      enabled: true,
      text: 'Car ID',
    },
    lineWidth: 1,
    gridLineWidth: 0,
    scrollbar: {
      enabled: true,
      showFull: false,
      liveRedraw: true,
    },
  },

  series: [
    {
      name: 'Fault Codes Before Breakdown',
      data: ScatterDataBeforeBreakdown,
      animation: false,
      marker: {
        radius: 3,
      },
      color: 'blue',
    },
    {
      name: 'Fault Codes After Breakdown',
      data: ScatterDataAfterBreakdown,
      animation: false,
      marker: {
        radius: 3,
      },
      color: 'orange',
    },
  ],

  legend: {
    enabled: false,
  },
};

Highcharts.SVGRenderer.prototype.symbols.cross = function(x, y, w, h) {
  return ['M', x, y, 'L', x + w, y + h, 'M', x + w, y, 'L', x, y + h, 'z'];
};

const repeatedRepairOptions = {
  tooltip: {
    enabled: false,
  },
  title: null,
  chart: {
    type: 'scatter',
    zoomType: 'xy',
    animation: false,
  },
  xAxis: {
    title: {
      enabled: true,
      text: 'Number of Days',
    },
    min: 0,
    max: 75,
    scrollbar: {
      enabled: true,
      showFull: false,
      liveRedraw: true,
    },
  },

  yAxis: {
    min: 0,
    max: 75,
    title: {
      enabled: true,
      text: 'Car ID',
    },
    lineWidth: 1,
    gridLineWidth: 0,
    scrollbar: {
      enabled: true,
      showFull: false,
      liveRedraw: true,
    },
  },

  series: [
    {
      name: 'Repeated Repair Points',
      data: RepeatedRepairPoints,
      animation: false,
      marker: {
        radius: 3,
      },
      color: 'blue',
      states: {
        hover: {
          enabled: true,
        },
        inactive: {
          opacity: 1,
        },
      },
    },
    {
      name: 'Repeated Repair Crosses',
      data: RepeatedRepairCrosses,
      animation: false,
      marker: {
        radius: 5,
        symbol: 'cross',
        lineColor: null,
        lineWidth: 1.5,
      },
      color: 'red',
      states: {
        hover: {
          enabled: true,
        },
        inactive: {
          opacity: 1,
        },
      },
    },
  ],

  legend: {
    enabled: false,
  },
};

const pmServiceVisitOptions = {
  tooltip: {
    enabled: false,
  },
  title: null,
  chart: {
    type: 'scatter',
    zoomType: 'xy',
    animation: false,
  },
  xAxis: {
    title: {
      enabled: true,
      text: 'Number of Days to Repair Service (left is before, right is after)',
    },
    min: -50,
    max: 50,
    scrollbar: {
      enabled: true,
      showFull: false,
      liveRedraw: true,
    },
    plotLines: [{ color: 'red', width: 2, value: 0, zIndex: 4 }],
  },

  yAxis: {
    min: 0,
    max: 75,
    title: {
      enabled: true,
      text: 'Car ID',
    },
    lineWidth: 1,
    gridLineWidth: 0,
    scrollbar: {
      enabled: true,
      showFull: false,
      liveRedraw: true,
    },
  },

  series: [
    {
      name: 'Points Before Service',
      data: PMServiceVisitTimesRed,
      animation: false,
      marker: {
        radius: 3,
      },
      color: 'red',
      states: {
        hover: {
          enabled: true,
        },
        inactive: {
          opacity: 1,
        },
      },
    },
    {
      name: 'Points After Service',
      data: PMServiceVisitTimesBlue,
      animation: false,
      marker: {
        radius: 3,
        symbol: 'circle',
      },
      color: 'blue',
      states: {
        hover: {
          enabled: true,
        },
        inactive: {
          opacity: 1,
        },
      },
    },
  ],

  legend: {
    enabled: false,
  },
};

class ShopPerformanceDashboard extends React.Component {
  componentDidMount() {
    AppStore.setSelectedSidebarKey('/shopperformance');
  }

  demoCharts = [
    {
      name: 'Quality of PM Analysis',
      imagePath: QualityOfPMAnalysis,
    },
    {
      name: 'Vehicle Repeat Visits',
      imagePath: VehicleRepeatVisits,
    },
    // {
    //   name: 'Vehicle Costs',
    //   imagePath: VehicleCosts,
    // },
    // {
    //   name: 'Average Cost Amount Per Visit',
    //   imagePath: AverageCostAmountPerVisit,
    // },
  ];

  render() {
    return (
      <>
        <DealershipChooser />
        {this.demoCharts.map((chart, index) => (
          <Row key={index} style={{ marginBottom: 10 }}>
            <Col span={24}>
              <FullWidthImage src={chart.imagePath} alt={chart.name} />
            </Col>
          </Row>
        ))}
        <GridContainer>
          <WidgetContainer>
            <h4>Repeated Repair/Replace Visits - CNG</h4>
            <WidgetToolTip title="This graph shows how often repairs and replacements are occurring close to each other." />
            <HighchartsReact
              highcharts={Highcharts}
              options={repeatedRepairOptions}
            />
          </WidgetContainer>
          <WidgetContainer>
            <h4>Number of PMs Occurring Around a Repair Visit</h4>
            <WidgetToolTip
              title="This graph shows how often PMs are occurring shortly after a repair visit. A large number of PMs occurring shortly
                                  after a repair could be an opportunity to save money by doing a PM while the vehicle is in the shop for a repair."
            />
            <GroupedBarChartNoLabel data={allPMVisits} loaded={true} />
          </WidgetContainer>
          <WidgetContainer>
            <h4>
              PM Service Visit Times Compared to Repair/Replace Visit Times -
              CNG
            </h4>
            <WidgetToolTip title="This graph shows how often repairs and replacements are occurring close to each other." />
            <HighchartsReact
              highcharts={Highcharts}
              options={pmServiceVisitOptions}
            />
          </WidgetContainer>
          <WidgetContainer>
            <h4>Vehicle Count by Number of Repairs</h4>
            <WidgetToolTip title="This shows how many vehicles received how many repairs in the past service record period" />
            <GroupedBarChart data={vehicleRepairCountData} loaded={true} />
          </WidgetContainer>
          <WidgetContainer>
            <h4>Vehicle Count by Number of Repeated Repairs</h4>
            <WidgetToolTip title="This shows how many vehicles received the same type of repair twice within a five day window" />
            <GroupedBarChart data={vehicleRepeatedRepairData} loaded={true} />
          </WidgetContainer>
          {/*}
          <WidgetContainer>
            <h4>Number of PMs Occurring Before a Repair Visit</h4>
            <WidgetToolTip title='This graph shows how often repairs occurred shortly after a PM. A large number of repairs
                                  shortly after PMs may indicate that issues are not being found in PMs.' />
            <GroupedBarChart data={pmVisitsBeforeRepairData} loaded={true} />
          </WidgetContainer>
          <WidgetContainer>
            <h4>Number of PMs Occurring After a Repair Visit</h4>
            <WidgetToolTip title='This graph shows how often PMs are occurring shortly after a repair visit. A large number of PMs occurring shortly
                                  after a repair could be an opportunity to save money by doing a PM while the vehicle is in the shop for a repair.'/>
            <GroupedBarChart data={pmVisitsAfterRepairData} loaded={true} />
          </WidgetContainer>*/}
          <WidgetContainer>
            <h4>Fault Code Occurences Around a Breakdown</h4>
            <WidgetToolTip
              title="This graph shows how often fault codes of major priority appear before and after a breakdown. Each vehicle shown has
                                  broken down at least once. The blue dots happened before and the orange dots happened after."
            />
            <HighchartsReact highcharts={Highcharts} options={scatterOptions} />
          </WidgetContainer>
        </GridContainer>
      </>
    );
  }
}

export default withRouter(observer(ShopPerformanceDashboard));
