import React, { Component } from 'react';
import {
  Table,
  Button,
  Input,
  Row,
  Col,
  Tag,
  notification,
  Popconfirm,
  Icon,
  Checkbox,
} from 'antd';
import { DealershipChooser } from 'components';
import { Logger } from 'stores/Classes';
import {
  AppStore,
  CarStore,
  ContactStore,
  CurrentUserStore,
  ShopStore,
} from 'stores';
import { observe } from 'mobx';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import WarrantyStore from 'stores/WarrantyStore';
import moment from 'moment-timezone';
import { get, isEmpty, set, startCase } from 'lodash';
import styled from 'styled-components';
import AddWarrantyModal from 'components/Warranties/AddWarrantyModal';
import WarrantyDetailsModal from 'components/Warranties/WarrantyDetailsModal';
import { webServiceProvider } from 'shared';
import StatusSettingsModal from 'components/Warranties/StatusSettingsModal';
import CurrentFilterBadges from 'components/WorkOrder/CurrentFilterBadges';

const { Search } = Input;

const WarrantiesTable = styled(Table)`
  .ant-table-pagination.ant-pagination {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 12px;
  }
`;

const FilterDropdown = styled.div`
  .filter-dropdown-list {
    padding: 8px 12px;
    border-bottom: solid 1px #e8e8e8;

    .filter-dropdown-list-item {
      margin-bottom: 5px;
    }
  }

  .filter-dropdown-actions {
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
  }

  &.assigned-to-dropdown {
    min-width: 300px;
    width: 300px;
  }
`;

class WarrantiesPage extends Component {
  state = {
    selectedRowKeys: [],
    isLoading: false,
    data: [],
    warrantyToEdit: null,
    isUpdatingWarranty: false,
    currentSearch: '',
    isWarrantyModalVisible: false,
    filters: {},
    pagination: {},
    sorter: {},
    assets: [],
    vendors: [],
    currentStatusFilters: [],
    shopSettings: null,
    isSavingWarranties: false,
    isStatusSettingsModalVisible: false,
    isStatusSettingsModalSaving: false,
  };

  componentDidMount() {
    AppStore.setSelectedSidebarKey('/warranties');
    this.init();
    this.loadShopSettings();
    this.loadAssetsAndVendors();
  }

  deburredSearch = null;

  componentDidUpdate(unused, prevState) {
    if (prevState.currentSearch !== this.state.currentSearch) {
      clearTimeout(this.deburredSearch);
      this.deburredSearch = setTimeout(() => {
        // force pagination to page 1
        this.setState(
          {
            selectedRowKeys: [],
            pagination: {
              ...this.state.pagination,
              current: 1,
            },
          },
          () => {
            this.init();
          }
        );
      }, 500);
    }
  }

  handleStatusFilterChange = (selectedKeys, confirm) => {
    this.setState(
      {
        currentStatusFilters: selectedKeys.map((key) => {
          const status = this.statusFilterOptions.find((s) => s.value === key);
          return {
            key: key,
            label: status?.text || key,
          };
        }),
      },
      () => {
        confirm();
        this.onChangeCurrentFilters();
      }
    );
  };

  handleResetFilters = (clearFilters, type = 'status') => {
    clearFilters();
    if (type === 'status') {
      this.setState(
        {
          currentStatusFilters: [],
        },
        () => {
          this.onChangeCurrentFilters();
        }
      );
    }
    return;
  };

  renderStatusFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      <div className="filter-dropdown-list">
        {this.statusFilterOptions.map((filter) => {
          return (
            <div className="filter-dropdown-list-item" key={filter.value}>
              <Checkbox
                checked={selectedKeys.includes(filter.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedKeys([...selectedKeys, filter.value]);
                  } else {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== filter.value)
                    );
                  }
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="filter-dropdown-actions">
        <Button
          onClick={() => this.handleResetFilters(clearFilters, 'status')}
          size="small"
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => this.handleStatusFilterChange(selectedKeys, confirm)}
        >
          OK
        </Button>
      </div>
    </FilterDropdown>
  );

  statusColors = {
    Active: 'green',
    'Expiring Soon': 'orange',
    Expired: 'red',
  };

  userTimezone = CurrentUserStore.user?.settings?.timezone || 'America/Toronto';

  miKmUserSettings =
    CurrentUserStore.user?.settings?.odometer === 'km' ? 'km' : 'mi';

  statusFilterOptions = [
    { text: 'Active', value: 'Active' },
    { text: 'Expiring Soon', value: 'Expiring Soon' },
    { text: 'Expired', value: 'Expired' },
  ];

  columns = [
    {
      title: 'Unit ID',
      dataIndex: 'id_car',
      key: 'id_car',
      render: (id_car) => {
        const asset = this.state.assets.find((asset) => asset.id === id_car);
        return asset ? asset.unitId : id_car;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [...this.statusFilterOptions],
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      ),
      filterDropdown: this.renderStatusFilterDropdown,
      sorter: true,
      render: (status) => (
        <Tag color={this.statusColors[status]} key={status}>
          {status}
        </Tag>
      ),
    },
    {
      title: 'Coverage Type',
      dataIndex: 'coverage_type',
      key: 'coverage_type',
      sorter: true,
      render: (coverage_type) => {
        return startCase(coverage_type);
      },
    },
    {
      title: 'Vendor',
      dataIndex: 'id_contact',
      key: 'id_contact',
      render: (vendorId) => {
        const vendor = this.state.vendors.find(
          (vendor) => vendor.id === vendorId
        );
        return vendor ? vendor.name : vendorId;
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (start_date) => {
        return moment(start_date)
          .tz(this.userTimezone)
          .format('ll');
      },
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      sorter: true,
      render: (end_date) => {
        return moment(end_date)
          .tz(this.userTimezone)
          .format('ll');
      },
    },
    {
      title: 'Start Meter',
      dataIndex: 'start_meter',
      key: 'start_meter',
      render: (start_meter) => {
        return `${start_meter} ${this.miKmUserSettings}`;
      },
    },
    {
      title: 'Range',
      dataIndex: 'range_meter',
      key: 'range_meter',
      render: (range_meter) => {
        return `${range_meter} ${this.miKmUserSettings}`;
      },
    },
    {
      title: 'End Meter',
      dataIndex: 'end_meter',
      key: 'end_meter',
      sorter: true,
      render: (end_meter) => {
        return `${end_meter} ${this.miKmUserSettings}`;
      },
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Button
          type="link"
          icon="edit"
          onClick={() => {
            this.showEditWarrantyModal(record);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  get currentFiltersApplied() {
    return [...this.state.currentStatusFilters];
  }

  onChangeCurrentFilters = () => {
    // update table filter based on the filters selected
    const filters = {
      status: [],
    };
    this.state.currentStatusFilters.forEach((filter) => {
      filters['status'].push(filter.key);
    });
    this.columns = this.columns.map((column) => {
      if (column.key === 'status') {
        return {
          ...column,
          filters: [...this.statusFilterOptions],
          filteredValue: filters['status'],
        };
      }
      return column;
    });
    this.init();
  };

  loadAssetsAndVendors = async () => {
    try {
      AppStore.addLoading('Loading assets and vendors');
      await this.getAssets();
      await this.getVendors();
    } catch (error) {
      Logger.error(error);
    } finally {
      AppStore.removeLoading('Loading assets and vendors');
    }
  };

  loadShopSettings = async () => {
    try {
      AppStore.addLoading('Loading shop settings');
      const response = await ShopStore.getShopSettings(
        ShopStore.currentShop.id
      );
      const shopSettings = get(response, 'result[0].settings', {});
      this.setState({ shopSettings });
    } catch (error) {
      Logger.error(error);
    } finally {
      AppStore.removeLoading('Loading shop settings');
    }
  };

  updateStatusSettings = async ({
    warrantyStatusChangeDays,
    warrantyStatusChangeMiles,
    // partStockLowStockLessThan,
  }) => {
    try {
      this.setState({ isStatusSettingsModalSaving: true });
      const shopSettings = isEmpty(this.state.shopSettings)
        ? {}
        : this.state.shopSettings;
      set(shopSettings, 'warrantyStatusChangeDays', warrantyStatusChangeDays);
      set(shopSettings, 'warrantyStatusChangeMiles', warrantyStatusChangeMiles);
      // set(shopSettings, 'partStockLowStockLessThan', partStockLowStockLessThan);
      await ShopStore.upsertShopSettings(
        ShopStore.currentShop.id,
        shopSettings
      );
      notification.success({
        message: 'Success',
        description: 'Status settings updated successfully',
      });
      this.setState({
        isStatusSettingsModalVisible: false,
      });
      await this.loadShopSettings();
      this.init();
    } catch (error) {
      Logger.error(error);
      notification.error({
        message: 'Error',
        description: 'An error occurred while updating status settings',
      });
    } finally {
      this.setState({ isStatusSettingsModalSaving: false });
    }
  };

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    await this.loadShopSettings();
    await this.loadAssetsAndVendors();
    await this.init();
  });

  getAssets = async () => {
    try {
      if (
        !ShopStore.currentShop.id ||
        ShopStore.currentShop.id === '-1' ||
        ShopStore.currentShop.id === -1
      ) {
        this.setState({ assets: [] });
        return;
      }
      const assets = await CarStore.getShopCarsBasicInfoAndReturn(
        ShopStore.currentShop.id
      );
      this.setState({ assets });
    } catch (error) {
      // notification.error({
      //   message: 'Error',
      //   description: error.message,
      // });
      Logger.error(error);
    }
  };

  getVendors = async () => {
    try {
      if (
        !ShopStore.currentShop.id ||
        ShopStore.currentShop.id === '-1' ||
        ShopStore.currentShop.id === -1
      ) {
        this.setState({ vendors: [] });
        return;
      }
      const vendors = await ContactStore.getShopContacts(
        ShopStore.currentShop.id
      );
      this.setState({ vendors });
    } catch (error) {
      // notification.error({
      //   message: 'Error',
      //   description: error.message,
      // });
      Logger.error(error);
    }
  };

  init = async () => {
    try {
      this.setState({ isLoading: true });
      const filters = {
        ...this.state.filters,
      };
      if (this.state.currentStatusFilters.length > 0) {
        filters.statuses = this.state.currentStatusFilters
          .map((filter) => filter.key)
          .join(',');
      }
      if (this.state.pagination.current) {
        const page = this.state.pagination.current;
        filters.limit = this.state.pagination.pageSize;
        filters.offset = (page - 1) * filters.limit;
      }
      if (this.state.sorter.column && this.state.sorter.order) {
        const field = this.state.sorter.field;
        const direction = this.state.sorter.order === 'ascend' ? '' : '-';
        filters.sortBy = `${direction}${field}`;
      }
      if (this.state.currentSearch) {
        filters.search = this.state.currentSearch;
      }
      const { data, meta } = await WarrantyStore.getWarranties(
        ShopStore.currentShop.id,
        filters
      );
      this.setState({
        data,
        pagination: { ...this.state.pagination, total: meta.total },
      });
    } catch (error) {
      // notification.error({
      //   message: 'Error',
      //   description: error.message,
      // });
      Logger.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  handleWarrantyModalSubmit = async (warrantiesToAdd) => {
    try {
      this.setState({ isLoading: true, isSavingWarranties: true });
      // for each assets, create a warranty
      const formattedWarranties = warrantiesToAdd.reduce((acc, warranty) => {
        warranty.assets.forEach((assetId) => {
          acc.push({
            carId: assetId,
            name: warranty.warrantyName,
            coverageType: warranty.coverageType,
            contactId: warranty.vendor,
            startDate: warranty.startDate,
            endDate: warranty.endDate,
            coverageDuration: warranty.coverageDuration,
            coverageDurationUnit: warranty.coverageDurationPeriod,
            startMeter: warranty.startMeter,
            endMeter: warranty.endMeter,
            rangeMeter: warranty.range,
            documents: warranty.documents,
            components: warranty.components,
          });
        });
        return acc;
      }, []);
      // upload documents if any
      await Promise.all(
        formattedWarranties.map(async (warranty) => {
          if (warranty.documents && warranty.documents.length) {
            const documents = await Promise.all(
              warranty.documents.map(async (file) => {
                const formData = new FormData();
                formData.append('singleInputFileName', file);
                try {
                  const data = await webServiceProvider.postFormData(
                    'dashboard/v1/uploadimage',
                    formData
                  );
                  if (data.fileUrl) {
                    return {
                      name: file.name,
                      url: data.fileUrl,
                    };
                  }
                } catch (e) {
                  return null;
                }
              })
            );
            warranty.documents = documents.filter((doc) => doc);
          }
        })
      );
      await WarrantyStore.createWarranties(
        formattedWarranties.map((warrantyToAdd) => {
          return {
            ...warrantyToAdd,
            shopId: ShopStore.currentShop.id,
          };
        })
      );
      notification.success({
        message: 'Success',
        description: 'Warranties added successfully',
      });
      await this.getVendors();
      await this.init();
      this.setState({
        isWarrantyModalVisible: false,
      });
    } catch (error) {
      // notification.error({
      //   message: 'Error',
      //   description: error.message,
      // });
      Logger.error(error);
    } finally {
      this.setState({ isLoading: false, isSavingWarranties: false });
    }
  };

  handleWarrantyDetailsModalSubmit = async ({ id, ...warrantyData }) => {
    try {
      this.setState({ isUpdatingWarranty: true });
      // upload documents if any
      if (warrantyData.documents && warrantyData.documents.length) {
        const newDocuments = warrantyData.documents.filter(
          (document) => !document.url
        );
        const newDocumentsAfterUpload = await Promise.all(
          newDocuments.map(async (file) => {
            const formData = new FormData();
            formData.append('singleInputFileName', file);
            try {
              const data = await webServiceProvider.postFormData(
                'dashboard/v1/uploadimage',
                formData
              );
              if (data.fileUrl) {
                return {
                  name: file.name,
                  url: data.fileUrl,
                };
              }
            } catch (e) {
              return null;
            }
          })
        );
        const validNewDocuments = newDocumentsAfterUpload.filter((doc) => doc);
        warrantyData.documents = [
          ...warrantyData.documents.filter((doc) => doc.url),
          ...validNewDocuments,
        ];
      }
      await WarrantyStore.updateWarranty(id, {
        ...warrantyData,
        shopId: ShopStore.currentShop.id,
        carId: warrantyData.assets[0],
        name: warrantyData.warrantyName,
        coverageType: warrantyData.coverageType,
        contactId: warrantyData.vendor,
        startDate: warrantyData.startDate,
        endDate: warrantyData.endDate,
        coverageDuration: warrantyData.coverageDuration,
        coverageDurationUnit: warrantyData.coverageDurationPeriod,
        startMeter: warrantyData.startMeter,
        endMeter: warrantyData.endMeter,
        rangeMeter: warrantyData.range,
        documents: warrantyData.documents,
        components: warrantyData.components,
      });
      notification.success({
        message: 'Success',
        description: 'Warranty updated successfully',
      });
      await this.getVendors();
      await this.init();
      this.setState({
        warrantyToEdit: null,
      });
    } catch (error) {
      // notification.error({
      //   message: 'Error',
      //   description: error.message,
      // });
      Logger.error(error);
    } finally {
      this.setState({ isUpdatingWarranty: false });
    }
  };

  showEditWarrantyModal = async (record) => {
    try {
      AppStore.addLoading('Getting warranty components');
      const { components } = await WarrantyStore.getWarrantyComponents(
        record.id
      );
      record.components = components;
      this.setState({
        warrantyToEdit: record,
      });
    } catch (error) {
      Logger.error('Error getting warranty components', error);
      AppStore.addError('Error getting warranty components');
    } finally {
      AppStore.removeLoading('Getting warranty components');
    }
  };

  onClickRemoveWarranties = async (selectedRowKeys) => {
    try {
      this.setState({ isLoading: true });
      await WarrantyStore.deleteWarranties(selectedRowKeys);
      notification.success({
        message: 'Success',
        description: 'Warranties removed successfully',
      });
      await this.init();
      this.setState({
        selectedRowKeys: [],
      });
    } catch (error) {
      AppStore.addError('Error removing warranties');
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { selectedRowKeys = [], data = [], currentSearch = '' } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div>
        <Row style={{ marginBottom: 10 }}>
          <Col span={12}>
            <h1>Warranties</h1>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              icon="plus"
              onClick={() => {
                this.setState({
                  isWarrantyModalVisible: true,
                });
              }}
            >
              Add New
            </Button>
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col span={24}>
            <DealershipChooser />
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col span={5}>
            <Search
              placeholder="Search by Unit ID or Name"
              value={currentSearch}
              onChange={(event) => {
                this.setState({
                  currentSearch: event.target.value,
                });
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: 12 }}>
          <Col
            span={12}
            style={{
              display: 'flex',
            }}
          >
            <Button
              type="primary"
              size="small"
              ghost
              onClick={() => {
                this.setState({
                  isStatusSettingsModalVisible: true,
                });
              }}
            >
              Status Settings
            </Button>
            <Popconfirm
              placement="bottom"
              title={
                'Are you sure you want to remove the selected warranties? This action cannot be undone.'
              }
              onConfirm={() => {
                this.onClickRemoveWarranties(selectedRowKeys);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                size="small"
                disabled={selectedRowKeys.length === 0}
                style={{ marginLeft: 8, marginRight: 8 }}
              >
                Remove Warranties
              </Button>
            </Popconfirm>
            <CurrentFilterBadges
              currentFiltersApplied={this.currentFiltersApplied}
              onClearAllFilters={() => {
                this.setState(
                  {
                    currentStatusFilters: [],
                  },
                  () => {
                    this.onChangeCurrentFilters();
                  }
                );
              }}
              onCloseTag={(filter) => {
                this.setState(
                  {
                    currentStatusFilters: this.state.currentStatusFilters.filter(
                      (f) => f.key !== filter.key
                    ),
                  },
                  () => {
                    this.onChangeCurrentFilters();
                  }
                );
              }}
            />
          </Col>
        </Row>
        <WarrantiesTable
          rowKey="id"
          rowSelection={rowSelection}
          columns={this.columns}
          dataSource={data}
          loading={this.state.isLoading}
          onChange={(pagination, filters, sorter) => {
            this.setState({ pagination, filters, sorter }, () => {
              this.init();
            });
          }}
          pagination={{
            ...this.state.pagination,
          }}
        />
        <AddWarrantyModal
          visible={this.state.isWarrantyModalVisible}
          finishing={this.state.isSavingWarranties}
          onOk={(warrantiesToAdd) => {
            this.handleWarrantyModalSubmit(warrantiesToAdd);
          }}
          onCancel={() => {
            this.setState({
              isWarrantyModalVisible: false,
            });
          }}
        />
        <WarrantyDetailsModal
          visible={this.state.warrantyToEdit !== null}
          saving={this.state.isUpdatingWarranty}
          warranty={this.state.warrantyToEdit}
          assets={this.state.assets}
          vendors={this.state.vendors}
          onCancel={() => {
            this.setState({
              warrantyToEdit: null,
            });
          }}
          onSubmit={(data) => {
            this.handleWarrantyDetailsModalSubmit(data);
          }}
        />
        <StatusSettingsModal
          visible={this.state.isStatusSettingsModalVisible}
          saving={this.state.isStatusSettingsModalSaving}
          initialData={this.state.shopSettings}
          onOk={(newSettings) => {
            this.updateStatusSettings(newSettings);
          }}
          onCancel={() => {
            this.setState({
              isStatusSettingsModalVisible: false,
            });
          }}
        />
      </div>
    );
  }
}

WarrantiesPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(WarrantiesPage);
