import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Spin,
  Button,
  InputNumber,
} from 'antd';

import {
  WorkOrderStatusEnum,
  WorkOrderRepairPriorityEnum,
  WorkOrderTypeOfServiceEnum,
} from 'stores/Classes/WorkOrderObject';
import CurrentUserStore from 'stores/CurrentUserStore';
import CarStore from 'stores/CarStore';
import { TableStore } from 'stores/abstract';
import { AppStore, ContactStore, PurchaseOrderStore, ShopStore } from 'stores';
import AddAssetModal from 'components/Modals/AddAssetModal';
import WarrantiesTable from 'components/Warranties/WarrantiesTable';
import WarrantyStore from 'stores/WarrantyStore';
import { Logger } from 'stores/Classes';
import PurchaseOrderAddModal from 'components/PurchaseOrders/PurchaseOrderAddModal';
import { customFleets } from 'shared';
import FormLegend from 'components/Forms/FormLegend';
import SelectContactTable from 'components/Contacts/SelectContactTable';

const { Option } = Select;

const fullWidth = {
  width: '100%',
};

class WorkOrderDetailsFormDesktop extends React.Component {
  static propTypes = {
    form: PropTypes.object,
    shopId: PropTypes.number,
    childRef: PropTypes.func,
    onCarSelect: PropTypes.func,
    onGetCarDetails: PropTypes.func,
    workOrder: PropTypes.object,
    shopUsers: PropTypes.array,
    isDisabled: PropTypes.bool,
  };

  state = {
    cars: [],
    allCars: [],
    loadingCarData: false,
    selectedCar: undefined,
    selectedCarFromForm: undefined,
    searchingCar: false,
    assetTypeDataSource: ['Vehicle', 'Trailer', 'Other'],
    addAssetModalVisible: false,
    addPOModalVisible: false,
    loadingAssetWarranties: false,
    warrantyFilters: {},
    warrantyPagination: {},
    warrantySorter: {},
    assetWarranties: [],
    vendors: [],
    purchaseOrders: [],
    preSelectedAssets: [],
  };

  carsTableStore = new TableStore();

  async componentDidMount() {
    // To disable submit button at the beginning.
    const { childRef } = this.props;
    childRef(this);

    await this.initAssets();

    this.loadShopVendors();
    this.loadShopPurchaseOrders();

    if (this.props.workOrder && this.props.workOrder.woSelectVehicle) {
      // get car details
      this.setState({ searchingCar: true, loadingCarData: true });
      const car = this.state.allCars.find(
        (car) => car.id === this.props.workOrder.woSelectVehicle
      );
      if (this.props.onGetCarDetails) {
        this.props.onGetCarDetails(car);
      }

      // if there is a car, get the car type and force it before setting the car
      if (car) {
        await this.props.form.setFieldsValue({
          woAssetType: _.capitalize(car.carType),
        });
      }

      this.setState(
        {
          selectedCar: this.props.workOrder.woSelectVehicle,
          // cars: [car],
          searchingCar: false,
          loadingCarData: false,
        },
        () => {
          this.onChangeFormCar(this.props.workOrder.woSelectVehicle);
        }
      );
    }
  }

  loadShopVendors = async () => {
    try {
      if (
        !ShopStore.currentShop.id ||
        ShopStore.currentShop.id === '-1' ||
        ShopStore.currentShop.id === -1
      ) {
        this.setState({ vendors: [] });
        return;
      }
      const vendors = await ContactStore.getShopContacts(
        ShopStore.currentShop.id
      );
      this.setState({ vendors });
    } catch (error) {
      // notification.error({
      //   message: 'Error',
      //   description: error.message,
      // });
      Logger.error(error);
    }
  };

  loadShopPurchaseOrders = async () => {
    try {
      if (
        !ShopStore.currentShop.id ||
        ShopStore.currentShop.id === '-1' ||
        ShopStore.currentShop.id === -1
      ) {
        this.setState({ purchaseOrders: [] });
        return;
      }
      const purchaseOrders = await PurchaseOrderStore.fetchShopPurchaseOrders(
        ShopStore.currentShop.id
      );
      this.setState({ purchaseOrders });
    } catch (error) {
      Logger.error(error);
    }
  };

  loadAssetWarranties = async (carId) => {
    try {
      this.setState({ loadingAssetWarranties: true, assetWarranties: [] });
      const filters = {
        ...this.state.warrantyFilters,
        carIds: carId,
      };
      if (filters.status && filters.status.length) {
        filters.statuses = filters.status.join(',');
      }
      if (this.state.warrantyPagination.current) {
        const page = this.state.warrantyPagination.current;
        filters.limit = this.state.warrantyPagination.pageSize;
        filters.offset = (page - 1) * filters.limit;
      }
      if (this.state.warrantySorter.column && this.state.warrantySorter.order) {
        const field = this.state.warrantySorter.field;
        const direction =
          this.state.warrantySorter.order === 'ascend' ? '' : '-';
        filters.sortBy = `${direction}${field}`;
      }
      const { data, meta } = await WarrantyStore.getWarranties(
        ShopStore.currentShop.id,
        filters
      );
      this.setState({
        assetWarranties: data,
        warrantyPagination: {
          ...this.state.warrantyPagination,
          total: meta.total,
        },
      });
    } catch (error) {
      Logger.error(error.toString());
    } finally {
      this.setState({ loadingAssetWarranties: false });
    }
  };

  get createdByOptions() {
    return this.props.shopUsers.map((user, index) => (
      <Option key={index} value={user.id}>
        {user.name}
      </Option>
    ));
  }

  get assignedToOptions() {
    return this.props.shopUsers.map((user, index) => (
      <Option key={index} value={user.id}>
        {user.name}
      </Option>
    ));
  }

  get assetOptions() {
    const filteredCarsBasedOnAssetType = this.state.cars.filter(
      (car) =>
        (car?.carType || '').toLowerCase() ===
        (this.props.form.getFieldValue('woAssetType') || '').toLowerCase()
    );
    return filteredCarsBasedOnAssetType.map((car, index) => {
      const vinInfo = car?.carType !== 'other' ? ` - ${car?.vin}` : '';
      return (
        <Option key={index} value={car?.id}>
          {`${car?.carName ||
            car?.unitId ||
            car?.make + ' ' + car?.model + ' ' + car?.year}${vinInfo}`}
        </Option>
      );
    });
  }

  initAssets = async () => {
    this.setState({ searchingCar: true, loadingCarData: true });
    const assets = await this.getShopCars();
    this.setState({
      allCars: assets && assets.length ? [...assets] : [],
      cars: assets && assets.length ? [...assets] : [],
      selectedCar: undefined,
      searchingCar: false,
      loadingCarData: false,
    });
  };

  getShopCars = async () => {
    return CarStore.getShopCarsBasicInfoAndReturn(ShopStore.currentShop.id);
  };

  searchForCars = async (search) => {
    this.setState({ searchingCar: true });
    const cars = await this.state.allCars;
    const filteredCars = cars.filter((car) => {
      return (
        (car?.carName || '').toLowerCase().includes(search.toLowerCase()) ||
        (car?.unitId || '').toLowerCase().includes(search.toLowerCase()) ||
        (car?.vin || '').toLowerCase().includes(search.toLowerCase())
      );
    });
    this.setState({ cars: filteredCars, searchingCar: false });
  };

  onCarSearch = async (value, event) => {
    if (!value) {
      return this.setState({ cars: this.state.allCars });
    }
    this.searchForCars(value);
  };

  onCarSearchChange = (selectedCar) => {
    this.setState({ selectedCar });
    if (this.props.onCarSelect) {
      this.props.onCarSelect(selectedCar);
    }
    if (this.props.onGetCarDetails) {
      const car = this.state.allCars.find((car) => car.id === selectedCar);
      this.props.onGetCarDetails(car);
    }
  };

  onChangeFormCar = (value) => {
    this.setState(
      {
        selectedCarFromForm: value,
        preSelectedAssets: [value], // this is used to add warranty to the asset
      },
      () => {
        // load asset warranties
        this.loadAssetWarranties(value);

        if (this.props.onCarSelect) {
          this.props.onCarSelect(value);
        }
      }
    );
  };

  getCurrentLoggedUser = () => {
    return CurrentUserStore.user.id;
  };

  onAddAssets = async (newAssets) => {
    this.setState({
      addAssetModalVisible: false,
    });
    if (newAssets.length) {
      try {
        for (const asset of newAssets) {
          await CarStore.createGenericAsset({
            vin: asset.vin,
            source: 'user',
            assetType: asset.assetType,
            shopId: ShopStore.currentShop.id,
            licensePlate: '',
            baseMileage: asset.mileage | 0,
            unitId: asset.unitId,
            engineHours: asset.engineHours | 0,
          });
        }
        AppStore.addSuccess('Asset added successfully');
        await this.initAssets();
      } catch (error) {
        console.log(error);
        AppStore.addError('Error adding assets');
      }
    }
  };

  onAddPurchaseOrder = async (newPurchaseOrder) => {
    this.setState({
      addPOModalVisible: false,
    });
    if (newPurchaseOrder) {
      try {
        const newPO = await PurchaseOrderStore.savePurchaseOrder(
          newPurchaseOrder
        );
        AppStore.addSuccess('Purchase Order added successfully');
        await this.loadShopPurchaseOrders();
        // pre-select the purchase order
        this.props.form.setFieldsValue({ purchase_order_id: newPO.id });
      } catch (error) {
        AppStore.addError('Error adding Purchase Order');
      }
    }
  };

  render() {
    const {
      getFieldDecorator,
      getFieldError,
      isFieldTouched,
    } = this.props.form;

    const statusOptions = Object.keys(WorkOrderStatusEnum)
      .map((workOrderStatusKey) => ({
        key: workOrderStatusKey,
        value: WorkOrderStatusEnum[workOrderStatusKey],
      }))
      .map((workOrderStatus) => {
        return (
          <Option key={workOrderStatus.key} value={workOrderStatus.key}>
            {workOrderStatus.value}
          </Option>
        );
      });

    const priorityOptions = Object.keys(WorkOrderRepairPriorityEnum)
      .map((woRepairPriorityKey) => ({
        key: woRepairPriorityKey,
        value: WorkOrderRepairPriorityEnum[woRepairPriorityKey],
      }))
      .map((woRepairPriority) => {
        return (
          <Option key={woRepairPriority.key} value={woRepairPriority.key}>
            {woRepairPriority.value}
          </Option>
        );
      });

    const typeOptions = Object.keys(WorkOrderTypeOfServiceEnum)
      .map((woRepairPriorityKey) => ({
        key: woRepairPriorityKey,
        value: WorkOrderTypeOfServiceEnum[woRepairPriorityKey],
      }))
      .map((woRepairPriority) => {
        return (
          <Option key={woRepairPriority.key} value={woRepairPriority.key}>
            {woRepairPriority.value}
          </Option>
        );
      });

    const filterOption = (input, option) => {
      return (
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    };

    const woInvoiceNumberError =
      isFieldTouched('woInvoiceNumber') && getFieldError('woInvoiceNumber');
    const woCreatedByError =
      isFieldTouched('woCreatedBy') && getFieldError('woCreatedBy');
    const woAssignedToError =
      isFieldTouched('woAssignedTo') && getFieldError('woAssignedTo');
    const woSelectVehicleError =
      isFieldTouched('woSelectVehicle') && getFieldError('woSelectVehicle');
    const woStatusError =
      isFieldTouched('woStatus') && getFieldError('woStatus');
    const woRepairTypeError =
      isFieldTouched('woRepairType') && getFieldError('woRepairType');
    const woRepairPriorityError =
      isFieldTouched('woRepairPriority') && getFieldError('woRepairPriority');

    return (
      <Form onSubmit={this.onSave}>
        <FormLegend>Work Order Details</FormLegend>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Work order number:"
              validateStatus={woInvoiceNumberError ? 'error' : ''}
              help={woInvoiceNumberError || ''}
            >
              {getFieldDecorator('woInvoiceNumber', {
                rules: [
                  { required: true, message: 'Work order number is required!' },
                ],
                initialValue: this.props.workOrder.woInvoiceNumber,
              })(
                <Input
                  prefix="#"
                  allowClear={true}
                  placeholder="#000000"
                  disabled={this.props.isDisabled}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Created by:"
              validateStatus={woCreatedByError ? 'error' : ''}
              help={woCreatedByError || ''}
            >
              {getFieldDecorator('woCreatedBy', {
                rules: [{ required: true, message: 'Created by is required!' }],
                initialValue:
                  this.props.workOrder.woCreatedBy ||
                  this.getCurrentLoggedUser(),
              })(
                <Select
                  style={fullWidth}
                  placeholder="Select a User"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  disabled={this.props.isDisabled}
                >
                  {this.createdByOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Assigned To:"
              validateStatus={woAssignedToError ? 'error' : ''}
              help={woAssignedToError || ''}
            >
              {getFieldDecorator('woAssignedTo', {
                rules: [
                  { required: true, message: 'Assigned to is required!' },
                ],
                initialValue: this.props.workOrder.woAssignedTo,
              })(
                <Select
                  style={fullWidth}
                  placeholder="Select a User"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  disabled={this.props.isDisabled}
                >
                  {this.assignedToOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Asset Type:">
              {getFieldDecorator('woAssetType', {
                rules: [],
                initialValue: this.props.workOrder.woAssetType,
              })(
                <Select
                  // dataSource={this.state.assetTypeDataSource}
                  style={fullWidth}
                  placeholder="Asset Type"
                  disabled={this.props.isDisabled}
                  onChange={(value) =>
                    // set form field value
                    this.props.form.setFieldsValue({ woSelectVehicle: null })
                  }
                >
                  <Option value="Vehicle">Vehicle</Option>
                  <Option value="Trailer">Trailer</Option>
                  <Option value="Other">Other</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            {this.state.loadingCarData ? (
              <Spin tip="Loading asset data..." />
            ) : (
              <>
                <Form.Item
                  label="Select Asset or Miscellaneous Equipment:"
                  validateStatus={woSelectVehicleError ? 'error' : ''}
                  help={woSelectVehicleError || ''}
                  style={{ marginBottom: 3 }}
                >
                  {getFieldDecorator('woSelectVehicle', {
                    rules: [{ required: true, message: 'Asset is required!' }],
                    initialValue: this.props.workOrder.woSelectVehicle,
                    onChange: this.onChangeFormCar,
                  })(
                    <Select
                      style={fullWidth}
                      onChange={this.onCarSearchChange}
                      onSearch={this.onCarSearch}
                      placeholder="Search by Unit ID"
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      loading={this.state.searchingCar}
                      disabled={this.props.isDisabled}
                    >
                      {this.assetOptions}
                    </Select>
                  )}
                </Form.Item>
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  icon="plus"
                  onClick={() => {
                    this.setState({
                      addAssetModalVisible: true,
                    });
                  }}
                >
                  Add Asset
                </Button>
              </>
            )}
          </Col>
          {customFleets.ufpFleet.includes(
            ShopStore.currentShop.id
          ) && (
            <Col span={24}>
              <Form.Item label="Purchase Order:" style={{ marginBottom: 3 }}>
                {getFieldDecorator('purchase_order_id', {
                  rules: [],
                  initialValue: this.props.workOrder.purchase_order_id,
                })(
                  <Select
                    style={fullWidth}
                    placeholder="Select a Purchase Order"
                    disabled={this.props.isDisabled}
                    onChange={(value) =>
                      this.props.form.setFieldsValue({
                        purchase_order_id: null,
                      })
                    }
                  >
                    {this.state.purchaseOrders.map((purchaseOrder) => {
                      let poName = `#${purchaseOrder.purchase_order_number}`;
                      if (purchaseOrder.reference_number) {
                        poName += ` - ${purchaseOrder.reference_number}`;
                      }
                      return (
                        <Option key={purchaseOrder.id} value={purchaseOrder.id}>
                          {poName}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
              <Button
                style={{ padding: 0 }}
                type="link"
                icon="plus"
                onClick={() => {
                  this.setState({
                    addPOModalVisible: true,
                  });
                }}
              >
                Add Purchase Order
              </Button>
            </Col>
          )}

          <Col span={8}>
            <Form.Item
              label="Status:"
              validateStatus={woStatusError ? 'error' : ''}
              help={woStatusError || ''}
            >
              {getFieldDecorator('woStatus', {
                initialValue: this.props.workOrder.woStatus,
                rules: [{ required: true, message: 'Status is required!' }],
              })(
                <Select
                  style={fullWidth}
                  placeholder="Select a Status"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  disabled={this.props.isDisabled}
                >
                  {statusOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Repair Type:"
              validateStatus={woRepairTypeError ? 'error' : ''}
              help={woRepairTypeError || ''}
            >
              {getFieldDecorator('woRepairType', {
                initialValue: this.props.workOrder.woRepairType,
                rules: [
                  { required: true, message: 'Repair Type is required!' },
                ],
              })(
                <Select
                  style={fullWidth}
                  placeholder="Select a Repair Type"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  disabled={this.props.isDisabled}
                >
                  {typeOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Repair Priority:"
              validateStatus={woRepairPriorityError ? 'error' : ''}
              help={woRepairPriorityError || ''}
            >
              {getFieldDecorator('woRepairPriority', {
                initialValue: this.props.workOrder.woRepairPriority,
                rules: [
                  { required: true, message: 'Repair Priority is required!' },
                ],
              })(
                <Select
                  style={fullWidth}
                  placeholder="Select a Repair Priority"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  disabled={this.props.isDisabled}
                >
                  {priorityOptions}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Issue report date:">
              {getFieldDecorator('woIssueReportDate', {
                initialValue: this.props.workOrder.woIssueReportDate,
              })(
                <DatePicker
                  format={'MM/DD/YYYY HH:mm:ss'}
                  style={{ width: '100%' }}
                  showTime
                  placeholder="Select date and time"
                  disabled={this.props.isDisabled}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Work Order Start:">
              {getFieldDecorator('woStartDate', {
                initialValue: this.props.workOrder.woStartDate,
              })(
                <DatePicker
                  format={'MM/DD/YYYY HH:mm:ss'}
                  style={{ width: '100%' }}
                  showTime
                  placeholder="Select date and time"
                  disabled={this.props.isDisabled}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Work Order Completed:">
              {getFieldDecorator('woCompletedDate', {
                initialValue: this.props.workOrder.woCompletedDate,
              })(
                <DatePicker
                  style={{ width: '100%' }}
                  showTime
                  placeholder="Select date and time"
                  disabled={this.props.isDisabled}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Mileage:">
              {getFieldDecorator('mileage', {
                initialValue: this.props.workOrder.mileage,
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Mileage"
                  disabled={this.props.isDisabled}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Engine Hours:">
              {getFieldDecorator('engineHours', {
                initialValue: this.props.workOrder.engineHours,
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Engine Hours"
                  disabled={this.props.isDisabled}
                />
              )}
            </Form.Item>
          </Col>
          {customFleets.ufpFleet.includes(
            ShopStore.currentShop.id
          ) && (
            <Col span={24}>
              <Form.Item label="Additional Notes:">
                {getFieldDecorator('additionalNotes', {
                  initialValue: this.props.workOrder.additionalNotes,
                })(
                  <Input.TextArea
                    style={{ width: '100%' }}
                    placeholder="Additional Notes"
                    disabled={this.props.isDisabled}
                    rows={6}
                  />
                )}
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <div style={{ marginTop: 15, marginBottom: 15 }}>
              <FormLegend>Warranties on this Asset</FormLegend>
              <WarrantiesTable
                isLoading={this.state.loadingAssetWarranties}
                data={this.state.assetWarranties}
                assets={this.state.allCars}
                vendors={this.state.vendors}
                preSelectedAssets={this.state.preSelectedAssets}
                onUpdate={() => {
                  this.loadAssetWarranties(this.state.selectedCarFromForm);
                }}
                onChangePagination={({ pagination, filters, sorter }) => {
                  this.setState(
                    {
                      warrantyPagination: pagination,
                      warrantyFilters: filters,
                      warrantySorter: sorter,
                    },
                    () => {
                      this.loadAssetWarranties(this.state.selectedCarFromForm);
                    }
                  );
                }}
              />
            </div>
          </Col>
        </Row>

        {customFleets.ufpFleet.includes(ShopStore.currentShop.id) && (
          <Row gutter={16}>
            <Col span={24}>
              <FormLegend>Driver Report Details</FormLegend>
            </Col>
            <Col span={8}>
              <Form.Item label="Driver Name">
                {getFieldDecorator('driver_name', {
                  rules: [],
                  initialValue: this.props.workOrder.driver_name,
                })(
                  <Input style={fullWidth} disabled={this.props.isDisabled} />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Driver Number">
                {getFieldDecorator('driver_number', {
                  rules: [],
                  initialValue: this.props.workOrder.driver_number,
                })(
                  <Input style={fullWidth} disabled={this.props.isDisabled} />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Location">
                {getFieldDecorator('driver_location', {
                  rules: [],
                  initialValue: this.props.workOrder.driver_location,
                })(
                  <Input style={fullWidth} disabled={this.props.isDisabled} />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Time of Event">
                {getFieldDecorator('time_of_event', {
                  rules: [],
                  initialValue: this.props.workOrder.time_of_event,
                })(
                  <DatePicker
                    format={'MM/DD/YYYY HH:mm:ss'}
                    style={{ width: '100%' }}
                    showTime
                    placeholder="Select date and time"
                    disabled={this.props.isDisabled}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Weight of Vehicle">
                {getFieldDecorator('vehicle_weight', {
                  rules: [],
                  initialValue: this.props.workOrder.vehicle_weight,
                })(
                  <Input style={fullWidth} disabled={this.props.isDisabled} />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Driver Notes">
                {getFieldDecorator('driver_notes', {
                  rules: [],
                  initialValue: this.props.workOrder.driver_notes,
                })(
                  <Input style={fullWidth} disabled={this.props.isDisabled} />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Billing Through">
                {getFieldDecorator('billing_through', {
                  rules: [],
                  initialValue: this.props.workOrder.billing_through,
                })(
                  <Select style={fullWidth} disabled={this.props.isDisabled}>
                    <Option key={1}>
                      National account, reference the DR# 0372704
                    </Option>
                    <Option key={2}>
                      National Account, Bridgestone (account number 956315)
                    </Option>
                    <Option key={3}>
                      National Accounts - Bridgestone (Account #957128)
                    </Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={16}>
          <Col span={24}>
            <FormLegend>Vendor Details</FormLegend>
          </Col>
          <Col span={24}>
            {customFleets.ufpFleet.includes(
              ShopStore.currentShop.id
            ) && (
              <SelectContactTable
                contacts={this.state.vendors}
                onSelectChange={(selectedRowKeys) => {
                  this.props.form.setFieldsValue({
                    vendor_id: selectedRowKeys[0],
                  });
                }}
              />
            )}
            {/* just make the real form item invisible */}
            <Form.Item
              style={{
                visibility: customFleets.ufpFleet.includes(
                  ShopStore.currentShop.id
                )
                  ? 'hidden'
                  : 'visible',
                height: customFleets.ufpFleet.includes(
                  ShopStore.currentShop.id
                )
                  ? 0
                  : 'auto',
              }}
              label="Vendor:"
            >
              {getFieldDecorator('vendor_id', {
                rules: [],
                initialValue: this.props.workOrder.vendor_id,
              })(
                <Select
                  // dataSource={this.state.assetTypeDataSource}
                  style={fullWidth}
                  placeholder="Select a Vendor"
                  disabled={this.props.isDisabled}
                  onChange={(value) =>
                    // set form field value
                    this.props.form.setFieldsValue({ vendor_id: null })
                  }
                >
                  {this.state.vendors.map((vendor) => (
                    <Option key={vendor.id} value={vendor.id}>
                      {vendor.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <AddAssetModal
          visible={this.state.addAssetModalVisible}
          hideVin={true}
          onOk={this.onAddAssets}
          onCancel={() => {
            this.setState({
              addAssetModalVisible: false,
            });
          }}
        />

        <PurchaseOrderAddModal
          visible={this.state.addPOModalVisible}
          onSave={this.onAddPurchaseOrder}
          onCancel={() => {
            this.setState({
              addPOModalVisible: false,
            });
          }}
        />
      </Form>
    );
  }
}

const WorkOrderFormDesktop = Form.create({ name: 'work_order_form' })(
  WorkOrderDetailsFormDesktop
);

export default WorkOrderFormDesktop;
