import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { AppStore, CurrentUserStore, ShopStore } from 'stores';
import styled from 'styled-components';
import {
  Form,
  Button,
  Typography,
  notification,
  InputNumber,
  Spin,
} from 'antd';

const { Text } = Typography;

const Container = styled.div`
  padding-right: 2vw;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px;
`;

class EditProfilePage extends Component {
  formRef = React.createRef();
  state = {
    shopId: 0,
    loadingInitial: true,
    savingForm: false,
    shopStores: [],
    markAsCompleteThresholdHours: {
      value: 5,
      validateStatus: 'success',
      errorMsg: null,
    },
    taxType: 'absolute',
    taxValue: 0,
    taxOptions: [],
  };

  async componentDidMount() {
    this.load(this.shopId);
    const { shop = [] } = CurrentUserStore.user;
    this.setState({
      shopId: ShopStore.currentShop.id,
      shopStores: shop.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      }),
    });
  }

  // Used in case whe want to validate the number
  validateNumber = (number) => {
    if (number < 1) {
      return {
        validateStatus: 'error',
        errorMsg: 'Number must be greater or equal to 1',
      };
    }
    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  };

  load = async (shopId) => {
    console.log(this.props.form);
    this.setState({
      taxType: '',
      taxValue: 0,
      taxOptions: [],
    });
    try {
      const { result } = await ShopStore.getShopSettings(shopId);
      if (
        !_.isEmpty(result) &&
        !_.isNil(result[0].settings) &&
        !_.isNil(result[0].settings.fleetsettings)
      ) {
        const fleetSettings = result[0].settings.fleetsettings;

        const value = _.get(fleetSettings, 'markAsCompleteThresholdHours', 5);

        this.props.form.setFieldsValue({
          taxType: fleetSettings.taxType,
          taxValue: fleetSettings.taxValue,
          taxOptions: fleetSettings.taxOptions,
        });
        this.setState(
          {
            markAsCompleteThresholdHours: {
              ...this.validateNumber(value),
              value,
            },
            taxType: fleetSettings.taxType,
            taxValue: fleetSettings.taxValue,
            taxOptions: fleetSettings.taxOptions,
          },
          () => {
            this.setState({ loadingInitial: false });
          }
        );
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      this.setState({ loadingInitial: false });
    }
  };

  handleChange = async (v) => {
    this.setState({
      shopId: v,
    });
    this.load(v);
  };

  // componentDidUpdate(prevProps, prevState) {
  //   // if (this.props.form.getFieldValue('taxType') === 'absolute') {
  //   //   try {
  //   //     this.props.form.setFieldsValue({
  //   //       taxOptions: [],
  //   //     });
  //   //   } catch (error) {
  //   //     console.error('Failed to set form values:', error);
  //   //   }
  //   //   // this.setState({
  //   //   //   selectedTaxOptions: [],
  //   //   // });
  //   // }
  // }

  updateFleetRules = (e) => {
    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      try {
        this.setState({ savingForm: true });
        AppStore.addLoading('Setting Fleet Settings');
        const settings = await ShopStore.getShopSettings(this.state.shopId);

        values = {
          ...values,
          ...{
            markAsCompleteThresholdHours: this.state
              .markAsCompleteThresholdHours.value,
          },
        };

        let currentFleetSettings = _.get(
          settings.result,
          '[0].settings.fleetsettings',
          {}
        );

        currentFleetSettings = { ...currentFleetSettings, ...values };

        _.set(
          settings.result,
          '[0].settings.fleetsettings',
          currentFleetSettings
        );

        await ShopStore.upsertShopSettings(
          this.state.shopId,
          settings.result[0].settings
        );

        notification.success({
          message: 'Rules updated',
          key: 'fleetRulesSuccess',
          description: <Container>The Fleet rules were updated!</Container>,
        });
      } catch (e) {
        notification.error({
          message: 'Oops!',
          key: 'fleetRulesError',
          description: (
            <Container>
              {<Text>Error to update the fleet rules</Text>}
            </Container>
          ),
        });
        console.log('error', e);
      } finally {
        AppStore.removeLoading('');
        this.setState({ savingForm: false });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return this.state.loadingInitial ? (
      <LoadingContainer>
        <Spin tip="Loading..." />
      </LoadingContainer>
    ) : (
      <React.Fragment>
        <Form
          ref={this.formRef}
          onSubmit={this.updateFleetRules}
          className="edit-fleet-rules-form"
        >
          <Form.Item
            label="Clear Fault Codes After"
            validateStatus={
              this.state.markAsCompleteThresholdHours.validateStatus
            }
            help={this.state.markAsCompleteThresholdHours.errorMsg}
          >
            {getFieldDecorator('markAsCompleteThresholdHours', {
              initialValue: this.state.markAsCompleteThresholdHours,
            })(
              <>
                <InputNumber
                  placeholder="5"
                  value={this.state.markAsCompleteThresholdHours.value}
                  onChange={(value) =>
                    this.setState({
                      markAsCompleteThresholdHours: {
                        ...this.validateNumber(value),
                        value,
                      },
                    })
                  }
                />
                <Text style={{ marginLeft: '3px' }} type="secondary">
                  Driving Hours
                </Text>
              </>
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              disabled={
                this.state.markAsCompleteThresholdHours.validateStatus !==
                'success'
              }
              loading={this.state.savingForm}
            >
              Update Rules
            </Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }
}

EditProfilePage.propTypes = {
  form: PropTypes.object.isRequired,
};

export default Form.create({ name: 'edit-fleet-rules-form' })(EditProfilePage);
