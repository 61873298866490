import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { observer } from 'mobx-react';
import AppStore from 'stores/App';
import LocationForm from './LocationForm';

const LocationFormModal = ({
  modalId,
  title,
  saving,
  defaultData,
  onSubmit,
  onCancel,
}) => {
  return (
    <Modal
      title={title}
      visible={AppStore.openModals.get(modalId)}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
    >
      <LocationForm
        saving={saving}
        defaultData={defaultData}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

LocationFormModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string,
  saving: PropTypes.bool,
  defaultData: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

LocationFormModal.defaultProps = {
  title: 'Location',
  saving: false,
  defaultData: {},
  onSubmit: () => {},
  onCancel: () => {},
};

export default observer(LocationFormModal);