import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import PurchaseOrdersPageHeader from 'components/PurchaseOrders/PurchaseOrdersPageHeader';
import PurchaseOrdersForm from 'components/PurchaseOrders/PurchaseOrdersForm';

import { Row, Col } from 'antd';
import { AppStore, ShopStore } from 'stores';
import PurchaseOrderStore from 'stores/PurchaseOrderStore';

class PurchaseOrdersPageAdd extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  state = {
    purchaseOrder: {},
    shopUsers: [],
    shopVendors: [],
  };


  componentDidMount() {
    AppStore.setSelectedSidebarKey('/purchase-orders');
  }

  randomPurchaseOrderNumber = Math.floor(100000 + Math.random() * 900000);

  onSave = async (payload) => {
    try {
      AppStore.addLoading('Saving');
      await PurchaseOrderStore.savePurchaseOrder(payload);
      AppStore.addSuccess(
        (ShopStore.currentShop.id === 374 ? 'Invoice' : 'Purchase Order') +
          ' saved successfully'
      );
      this.props.history.push('/purchase-orders');
    } catch (error) {
      AppStore.addError(error.message || 'Error saving purchase order');
    } finally {
      AppStore.removeLoading('Saving');
    }
  };

  render() {
    return (
      <Row>
        <Col span={24}>
          <PurchaseOrdersPageHeader
            title={`Add ${
              ShopStore.currentShop.id === 374 ? 'Invoice' : 'Purchase Order'
            }`}
            hideActions={true}
          />
        </Col>
        <Col span={18} offset={3}>
          <PurchaseOrdersForm
            childRef={() => {}}
            purchaseOrder={{
              purchase_order_number: this.randomPurchaseOrderNumber,
            }}
            onSave={this.onSave}
            onCancel={() => {
              this.props.history.push('/purchase-orders');
            }}
            isNew={true}
          />
        </Col>
      </Row>
    );
  }
}

export default withRouter(PurchaseOrdersPageAdd);
