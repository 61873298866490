import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'lodash';

import { Button, Spin, Empty, Card, Icon, Tooltip } from 'antd';

import { customFleets, webServiceProvider } from 'shared';
import { interpolateColors } from 'helpers/color-generator';

import { DoughnutChart } from 'shared/PitstopUI/PitstopChart';

import CarStore from 'stores/CarStore';

import AisinGraph from './AisinGraph';
import TimeSelector from './TimeSelector';
import PidsGraph from './PidsGraph';

const WideLabelText = styled.p`
  width: 50%;
  font-weight: 700;
  margin-bottom: 0;
  align-self: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 10px;

  button {
    margin: 10px auto 0px;
    width: fit-content;
  }
`;
const TextRow = styled.div`
  display: flex;
  margin-top: 4px;
  margin-bottom: 15%;
  height: 34px;
`;

const LabelText = styled.p`
  margin: 3%;
  font-weight: 700;
  align-self: center;
`;

const ContentText = styled.p`
  flex: 1;
  margin: 3%
  align-self: center;
  color: black;
`;

class AnalyticsView extends Component {
  state = {
    startTime: null,
    endTime: null,
    aisinData: null,
    isFetchingLatestData: false,
    isFetchingData: false,
    isCancelRequest: false,
  };

  async componentDidMount () {
    this.pids.reset();
    if (!this.vehicleDetailReport.loaded) {
      await this.props.car.getVehicleDetailReport();
    }
    if (this.shopName === 'aisin') {
      this.setState(
        {
          startTime: moment(new Date(2016, 6, 6)).startOf('days'),
          endTime: moment(new Date(2016, 6, 15)).endOf('days'),
          isFetchingLatestData: true,
        },
        async () => {
          await this.getSensorData();
          await this.getAisinData();

          this.setState({
            isFetchingLatestData: false,
          });
        }
      );
      return;
    }
  }

  componentWillUnmount () {
    this.pids.reset();
  }

  get vehicleDetailReport () {
    return this.props.car.detailReport;
  }
  get breakdownReasonsData () {
    if (CarStore.demo === false) {
      return {
        key: 'breakdowns',
        value: [],
      };
    }

    let vehicleDetail = this.vehicleDetailReport;

    let breakdownReasons = _.get(vehicleDetail, 'data.reasonBreakdown');

    let labels = [],
      value = [];

    if (!breakdownReasons) {
      if (_.isBoolean(CarStore.demo)) {
        value = [
          { name: 'Road Call', y: 1 },
          { name: 'Warranty', y: 20 },
          { name: 'Driver Report', y: 24 },
          { name: 'Routine', y: 4 },
          { name: 'PM', y: 7 },
          { name: 'Inspection', y: 4 },
          { name: 'Capitalization', y: 1 },
        ];
      }
    } else {
      labels = Object.keys(breakdownReasons);

      value = _.map(labels, (label) => ({
        name: label,
        y: breakdownReasons[label] || 0,
      }));
    }

    let colors = interpolateColors(labels.length);

    value = _.map(value, (v, i) => ({
      ...v,
      color: colors[i],
    }));

    return {
      seriesName: 'downtime',
      emptyMessage: 'Your vehicle has no downtime',
      value,
    };
  }
  get carId () {
    if (this.props.match) {
      return Number(this.props.match.params.id);
    }
    return Number(this.props.car.id);
  }

  get pids () {
    return CarStore.data.get(this.carId).pids;
  }

  get shopName () {
    return CarStore.data.get(this.carId).shopName;
  }

  get shopId () {
    return CarStore.data.get(this.carId).shopId;
  }

  renderChart = (data) => {
    return (
      <DoughnutChart
        chart={{
          height: 200,
          margin: [0, 200, 0, 0],
          legend: {
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'top',
            width: 150,
            x: -30,
            y: 30,
          },
        }}
        data={data}
        loaded={CarStore.demo === false || this.vehicleDetailReport.loaded}
      />
    );
  };

  get issuesData () {
    if (CarStore.demo === false) {
      return {
        key: 'issues',
        value: [],
      };
    }

    const { issueCounts } = this.props.car;
    let value = [
      {
        name: 'critical',
        color: '#eb3d34',
        y: Number(issueCounts.critical) || 0,
      },
      {
        name: 'major',
        color: '#e28931',
        y: Number(issueCounts.major) || 0,
      },
      {
        name: 'minor',
        color: '#e2eb34',
        y: Number(issueCounts.minor) || 0,
      },
    ];

    return {
      key: '',
      seriesName: 'issues',
      emptyMessage: 'Your vehicle has no Issues',
      value,
    };
  }
  getSensorData = async (replace = true, cancel = false) => {
    let { startTime, endTime } = this.state;
    let sampleSize = customFleets.gmrv.includes(this.shopId) ? 300000 : 1000;
    let allowedNull = customFleets.gmrv.includes(this.shopId);
    let excludeValues = customFleets.gmrv.includes(this.shopId)
      ? 'BatteryLevel,vin,#Satellites,tripId,tripIndicator,2Flow,2NDIR,2Operation,2Pump,2Sensors,FixType,Quality,deviceTimestamp,latitude,longitude'
      : 'deviceTimestamp,vin,latitude,longitude';

    await CarStore.data
      .get(this.carId)
      .getSensorData(
        startTime,
        endTime,
        1,
        replace,
        cancel,
        sampleSize,
        allowedNull,
        excludeValues
      );
  };

  getAisinData = async () => {
    let { startTime, endTime, isCancelRequest } = this.state;

    if (this.shopName === 'aisin' && !isCancelRequest) {
      let data = await webServiceProvider.getMany('scan/pids/aisin', {
        startTime,
        endTime,
      });

      this.setState({
        aisinData: data,
      });
    }
  };

  onCancelRequest = async () => {
    await this.getSensorData(false, true);

    this.setState({
      isCancelRequest: true,
    });
  };

  getAnalyticsView = () => {
    let {
      startTime,
      endTime,
      aisinData,
      isCancelRequest,
      isFetchingLatestData,
      isFetchingData,
    } = this.state;

    if ((!startTime && !endTime) || isCancelRequest) {
      return (
        <Empty description="Please select time range for data you want to get!" />
      );
    }

    if (isFetchingLatestData || isFetchingData) {
      return (
        <>
          <Spin
            tip={`${isFetchingLatestData
              ? 'Fetching latest sensor data. Please wait!'
              : 'Fetching sensor data from the dates you selected. Please wait!'
              }`}
          />
          <Button type="primary" onClick={this.onCancelRequest}>
            Cancel request
          </Button>
        </>
      );
    }

    return (
      <>
        <div style={{ margin: '10px 0px' }}>
          <PidsGraph
            carId={this.carId}
            shopName={this.shopName}
            data={this.pids.data.data}
            startTime={startTime}
            endTime={endTime}
          />
        </div>
        {this.shopName === 'aisin' && !isCancelRequest && (
          <AisinGraph
            data={aisinData}
            startTime={startTime}
            endTime={endTime}
          />
        )}
      </>
    );
  };

  render () {
    if (CarStore.demo === false) {
      return <h3>There is no data to display</h3>;
    }
    let vehicleDetail = this.vehicleDetailReport;
    let avgDaysBwService, maxDaysBwService;
    avgDaysBwService = _.get(vehicleDetail, 'data.avgDaysBwService');
    maxDaysBwService = _.get(vehicleDetail, 'data.maxDaysBwService');
    return (
      <div className="graphView">
        <h4>Analytics</h4>
        <div style={{ display: 'flex' }}>
          {(!_.isNil(avgDaysBwService) && !_.isNil(maxDaysBwService)) ?
            <Card style={{ margin: '1%' }}>
              <TextRow>
                <LabelText>Average Days Between Services</LabelText>
                <ContentText>
                  {Math.floor(Number(avgDaysBwService))}
                </ContentText>
              </TextRow>
              <TextRow>
                <LabelText>Max Days Between Services</LabelText>
                <ContentText>{maxDaysBwService}</ContentText>
              </TextRow>
            </Card>
            : null}
          <Card style={{ margin: '1%' }}>
            <TextRow>
              <WideLabelText>Health Score:</WideLabelText>
              <ContentText>{_.get(this.props.car.generalReport, 'data.data.healthScore', 'N/A') === 'N/A' ? 'N/A' : this.props.car.generalReport.loaded ? Math.round(_.get(this.props.car.generalReport, 'data.data.healthScore', 0) * 100) / 100 : 'Not yet loaded'} %</ContentText>
              <Tooltip title='This score ranks your vehicle against others in your fleet. A score of 100 indicates a healthy vehicle, a score of 0 indicates a vehicle that has frequent issues.'>
                <Icon type="info-circle" />
              </Tooltip>
            </TextRow>
            <TextRow>
              <WideLabelText>Idle Time (Past Month):</WideLabelText>
              <ContentText>{this.props.car.generalReport.loaded ?
                _.get(this.props.car.generalReport, 'data.data.idleTimePastMonth', 0) < 3600 ?
                  Math.round((_.get(this.props.car.generalReport, 'data.data.idleTimePastMonth', 0) / 60) * 100) / 100 :
                  Math.round((_.get(this.props.car.generalReport, 'data.data.idleTimePastMonth', 0) / 3600) * 100) / 100
                : 'Not yet loaded'} {' '}
                {this.props.car.generalReport.loaded ?
                  _.get(this.props.car.generalReport, 'data.data.idleTimePastMonth', 0) < 3600 ? 'Minutes' : 'Hours'
                  : null}</ContentText>
              <Tooltip title='This indicates how long the vehicle has spent idling in the past month.'>
                <Icon type="info-circle" />
              </Tooltip>
            </TextRow>
            <TextRow>
              <WideLabelText>Idle Percent:</WideLabelText>
              <ContentText>{this.props.car.generalReport.loaded ? Math.round(_.get(this.props.car.generalReport, 'data.data.idleFracPastMonth', 0) * 100) / 100 : 'Not yet loaded'} %</ContentText>
              <Tooltip title="This indicates the percentage of this vehicle's time that has been spent idling in the past month.">
                <Icon type="info-circle" />
              </Tooltip>
            </TextRow>
          </Card>
          <Card style={{ margin: '1%', 'minWidth': '30%' }}>
            <h6>Downtime Reasons</h6>
            {this.renderChart(this.breakdownReasonsData)}
          </Card>
        </div>
        <p>Vehicle Sensor Data will help you understand your car better. </p>
        <TimeSelector
          resetBtn={() =>
            this.setState(
              {
                startTime: null,
                endTime: null,
                aisinData: null,
                isFetchingData: false,
              },
              () => this.pids.reset()
            )
          }
          searchBtn={(startTime, endTime) => {
            this.setState(
              {
                startTime,
                endTime,
                aisinData: null,
                isFetchingData: true,
                isCancelRequest: false,
              },
              async () => {
                await this.getSensorData();
                await this.getAisinData();

                this.setState({
                  isFetchingData: false,
                });
              }
            );
          }}
        />

        <Container>{this.getAnalyticsView()}</Container>
      </div>
    );
  }
}

AnalyticsView.propTypes = {
  car: PropTypes.object,
  match: PropTypes.object,
};

export default observer(AnalyticsView);
