import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { observe } from 'mobx';
import PropTypes from 'prop-types';

import {
  CurrentUserStore,
  ShopStore,
  CarStore,
  CarStatusStore,
  ReportsDataStore,
  AppStore,
} from 'stores';
import { UserRoles } from 'stores/Classes/UserObject';
import HeaderMessage from '../Report/HeaderMessage';

import { DealershipChooser } from 'components';
import { Row, Col, Typography, Card, notification } from 'antd';
import _ from 'lodash';

import { DoughnutChart } from 'shared/PitstopUI/PitstopChart';

import VehiclesTable from './VehiclesTable';
import AssetTypeChooser from 'components/Vehicles/AssetTypeChooser';

import { Logger } from 'stores/Classes';
import VehicleAvailabilityCard from 'components/Vehicles/VehicleAvailabilityCard';
import DeviceStore from '../../stores/Classes/DeviceStore';
import PMCountWidget from 'components/Widgets/PMCountWidget';
import DVIRCountWidget from 'components/Widgets/DVIRCountWidget';
import SafetyInspectionsCountWidget from 'components/Widgets/SafetyInspectionsCountWidget';

const Wrapper = styled.div`
  position: relative;

  .summary-card {
    width: 100%;
    height: 357px;

    .ant-card-body {
      height: 100%;
    }

    .summary-card__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      button {
        span {
          color: #ececec;
        }
      }

      span {
        color: #1f3eb8;
      }

      .filter-helper {
        font-size: 0.8rem;
        font-style: italic;
        font-weight: normal;
      }
    }

    .list {
      padding-top: 2rem;
      max-height: 210px;
      overflow-y: auto;

      .item {
        font-size: 1rem;

        .count {
          font-size: 1.5rem;
          margin-right: 1rem;
        }

        .ant-progress-text {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.progress-item-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;

          .progress-item-wrapper__item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &:hover {
              cursor: pointer;

              span {
                text-decoration: underline;
                text-decoration-color: #9b9b9b;
                text-decoration-thickness: 1px;
                text-underline-offset: 3px;
              }
            }

            .progress-item-wrapper__item__title {
              font-size: 1.2rem;
              text-align: center;
            }
          }
        }
      }
    }

    .vehicleAvailability {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
`;

const { Title, Text } = Typography;

class CarsPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    device: PropTypes.object,
  };

  componentDidMount() {
    AppStore.setSelectedSidebarKey('/vehicles');
    this._isMounted = true;
    if (CurrentUserStore.user.role !== UserRoles.customer) {
      if (!ShopStore.loaded && !ShopStore.pending) {
        ShopStore.getShops();
      }
    }

    // check query params to see if currentType is set
    // and if it is, set the value to the currentType state
    const queryParams = new URLSearchParams(this.props.history.location.search);
    const currentType = queryParams.get('currentType');
    if (currentType) {
      this.assetsTableRef.handleSearchData();
      this.setState({ currentType });
    }

    this.loadTotalAssetsCount();
    this.loadVehicleAvailabilityAndShopStatuses();
    this.loadPMCounts();
    this.loadDvirStatuses();
    this.loadFaultCodeAndInsightsData();
    this.loadDviRSummary();

    // clear the query params
    this.clearQueryParams();
  }

  assetsTableRef = React.createRef();

  state = {
    isLoadingIssuesAndInsights: false,
    totalServiceCount: {
      total: 0,
      critical: 0,
      major: 0,
      minor: 0,
    },
    isLoadingVehicleAvailability: false,
    isLoadingPMStatuses: false,
    isLoadingDvirStatuses: false,
    isLoadingPMCounts: false,
    isLoadingSafetyInspectionCounts: false,
    isLoadingDvir: false,
    currentShopStatuses: [],
    vehicleAvailability: [],
    dvirStatuses: [],
    dvirValues: {},
    pmCounts: {
      overdue_count: '0',
      due_soon_count: '0',
    },

    faultCodesAndInsightsData: {
      key: '',
      seriesName: 'issues',
      emptyMessage: 'Your vehicle has no Issues',
      value: [
        {
          name: 'critical',
          color: '#eb3d34',
          y: 1 || 0,
        },
        {
          name: 'major',
          color: '#e28931',
          y: 2 || 0,
        },
        {
          name: 'minor',
          color: '#e2eb34',
          y: 3 || 0,
        },
      ],
    },
    runningData: {
      key: '',
      seriesName: 'issues',
      emptyMessage: 'Your vehicle has no Issues',
      value: [
        {
          name: 'critical',
          color: '#eb3d34',
          y: 1 || 0,
        },
        {
          name: 'major',
          color: '#e28931',
          y: 2 || 0,
        },
        {
          name: 'minor',
          color: '#e2eb34',
          y: 3 || 0,
        },
      ],
    },
    currentType: 'vehicles',
    loadingAssetsCount: false,
    assetsCountSummary: {},
  };

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    this.loadTotalAssetsCount();
    this.loadVehicleAvailabilityAndShopStatuses();

    this.loadPMCounts();
    this.loadDvirStatuses();
    this.loadFaultCodeAndInsightsData();
    this.clearQueryParams();
    this.loadDviRSummary();
  });

  componentWillUnmount() {
    this._isMounted = false;
    this.disposer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentType !== this.state.currentType) {
      this.loadVehicleAvailabilityAndShopStatuses();
      this.loadDviRSummary();
    }
  }

  loadTotalAssetsCount = async () => {
    try {
      this.setState({ loadingAssetsCount: true });
      const assetsCountSummary = await CarStore.getShopAssetsCountSummary(
        ShopStore.currentShop.id
      );
      if (this._isMounted) {
        this.setState({
          assetsCountSummary: _.first(assetsCountSummary) || {},
        });
      }
    } catch (error) {
      Logger.error(error);
      AppStore.addError(error.message || 'Failed to load assets count');
    } finally {
      if (this._isMounted) {
        this.setState({ loadingAssetsCount: false });
      }
    }
  };

  async loadPMCounts() {
    const shopId = ShopStore.currentShop.id;
    const { currentType } = this.state;
    try {
      this.setState({ isLoadingPMCounts: true });
      const pmCounts =
        currentType === 'vehicles'
          ? await CarStore.getShopPMStatusCounts(shopId)
          : await CarStore.getShopTrailersPMStatusCounts(shopId);

      this.setState({ pmCounts: pmCounts.data || {} });
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isLoadingPMCounts: false });
    }
  }

  formattedServiceCount(summaryData = []) {
    let critical = 0,
      major = 0,
      minor = 0;

    _.forEach(summaryData, (data) => {
      _.forEach(data[1], (item) => {
        if (item.priority === 0 || item.priority === 1) {
          minor += item.count;
        }

        if (item.priority === 2 || item.priority === 3) {
          major += item.count;
        }

        if (item.priority === 4 || item.priority === 5) {
          critical += item.count;
        }
      });
    });

    const response = {
      total: _.sum([critical, major, minor]),
      critical: critical,
      major: major,
      minor: minor,
    };

    return response;
  }

  async loadFaultCodeAndInsightsData() {
    try {
      this.setState({ isLoadingIssuesAndInsights: true });
      const summaryData = await ReportsDataStore.getSummaryReport({
        shopId: ShopStore.currentShop.id,
        type: 'maintenanceSummary',
      });
      let data = _.map(summaryData, (value, key) => {
        return [key, value];
      });
      this.setState({
        totalServiceCount: this.formattedServiceCount(data),
        isLoadingIssuesAndInsights: false,
      });
    } catch (error) {
      Logger.error(error);
      notification.error({
        message: 'Error',
        description: 'Unable to load fault codes and insights data',
      });
    } finally {
      this.setState({ isLoadingIssuesAndInsights: false });
    }
  }

  async loadDviRSummary() {
    const { currentType } = this.state;

    this.setState({ isLoadingDvir: true });
    try {
      const summaryData =
        currentType === 'vehicles'
          ? await ReportsDataStore.getShopVehiclesDvirStatusSummary(
              ShopStore.currentShop.id
            )
          : await ReportsDataStore.getShopTrailersDvirStatusSummary(
              ShopStore.currentShop.id
            );
      this.setState({
        dvirValues: summaryData,
      });
    } catch (error) {
      Logger.error(error);
      notification.error({
        message: 'Error',
        description: 'Unable to load dvir report data',
      });
    } finally {
      this.setState({ isLoadingDvir: false });
    }
  }

  async loadDvirStatuses() {
    try {
      this.setState({ isLoadingDvirStatuses: true });
      const dvirStatuses = await ReportsDataStore.getDvirStatusSummary();
      this.setState({ dvirStatuses: dvirStatuses });
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isLoadingDvirStatuses: false });
    }
  }

  clearQueryParams = () => {
    const queryParams = new URLSearchParams(this.props.history.location.search);
    queryParams.delete('status');
    queryParams.delete('dvir');
    this.props.history.push({
      search: queryParams.toString(),
    });
  };

  async loadVehicleAvailabilityAndShopStatuses() {
    const { currentType } = this.state;
    const shopId = ShopStore.currentShop.id;

    const currentTypeKey = {
      vehicles: 'vehicle',
      trailers: 'trailer',
    };

    this.setState({ isLoadingVehicleAvailability: true });

    try {
      const vehicleAvailability = await CarStore.getShopVehicleAvailabilitySummary(
        ShopStore.currentShop.id,
        currentTypeKey[currentType]
      );
      const shopStatuses = await CarStatusStore.fetchShopCarStatuses(shopId);
      const availabilityKeys = Object.keys(vehicleAvailability);
      const validTotal = availabilityKeys.reduce((acc, key) => {
        return acc + vehicleAvailability[key];
      }, 0);
      let formattedVehicleAvailability = availabilityKeys
        .map((key) => {
          const value = vehicleAvailability[key];
          let percent = (value / validTotal) * 100;
          let shopStatus = shopStatuses
            ? shopStatuses.find((status) => status.key === key)
            : null;
          if (!shopStatus) {
            // get the default status
            shopStatus = shopStatuses
              ? shopStatuses.find((status) => status.is_default)
              : null;
          }
          return {
            title: shopStatus?.description || 'Running',
            value: percent,
            color: shopStatus?.color || 'green',
            count: value,
          };
        })
        .sort((a, b) => b.value - a.value);
      // combine duplicated descriptions
      formattedVehicleAvailability = formattedVehicleAvailability.reduce(
        (acc, item) => {
          const existingItem = acc.find((i) => i.title === item.title);
          if (existingItem) {
            existingItem.value += item.value;
            existingItem.count += item.count;
            return acc;
          }
          acc.push(item);
          return acc;
        },
        []
      );

      this.loadPMCounts();
      this.setState({
        vehicleAvailability: formattedVehicleAvailability,
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoadingVehicleAvailability: false });
    }
  }

  loadShopStatuses = async () => {
    const shopId = ShopStore.currentShop.id;
    try {
      this.setState({ isLoadingVehicleAvailability: true });
      const shopStatuses = await CarStatusStore.fetchShopCarStatuses(shopId);
      this.setState({ currentShopStatuses: shopStatuses || [] });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoadingVehicleAvailability: false });
    }
  };

  addFiltersToQuery = (type = '', value) => {
    if (!type) {
      throw new Error('Type is required');
    }
    // push the filter to the URL
    // get the current query params
    const queryParams = new URLSearchParams(this.props.history.location.search);

    // set the type query param
    queryParams.set(type, value);

    // push the new query params to the URL
    this.props.history.push({
      search: queryParams.toString(),
    });
  };

  handlePMsCountClick = (sectionKey) => {
    if (this.assetsTableRef) {
      this.assetsTableRef.handlePmsCountSectionClick(sectionKey);
    }
  };

  handleSafetyInspectionsCountClick = (sectionKey) => {
    if (this.assetsTableRef) {
      this.assetsTableRef.handleSafetyInspectionsCountSectionClick(sectionKey);
    }
  };

  render() {
    const { device } = DeviceStore;

    return (
      <Wrapper>
        <Row>
          <Col span={24}>
            <Title>Assets</Title>
          </Col>
        </Row>
        <Row>
          <Col span={['mobile', 'tablet'].includes(device) ? 24 : 16}>
            <DealershipChooser />
            <HeaderMessage />
          </Col>
        </Row>
        <Row
          style={{
            marginTop: '1rem',
          }}
        >
          <Col span={6}>
            <AssetTypeChooser
              currentType={this.state.currentType}
              onChange={(type) => {
                this.setState({ currentType: type }, () => {
                  // set the current type to query param
                  this.addFiltersToQuery('currentType', type);
                  this.assetsTableRef.loadData(type);
                });
              }}
              loading={this.state.loadingAssetsCount}
              vehiclesCount={this.state.assetsCountSummary?.countVehicles || 0}
              trailersCount={this.state.assetsCountSummary?.countTrailers || 0}
            />
          </Col>
        </Row>
        {!['mobile', 'tablet'].includes(device) && (
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <VehicleAvailabilityCard
                isLoadingVehicleAvailability={
                  this.state.isLoadingVehicleAvailability
                }
                vehicleAvailability={this.state.vehicleAvailability}
                addFiltersToQuery={this.addFiltersToQuery}
              />
            </Col>
            {this.state.currentType === 'vehicles' && (
              <Col span={6}>
                <Card className="summary-card">
                  <div className="summary-card__title">
                    <Text strong>Fault Codes & Insights</Text>
                  </div>

                  <Row style={{ marginTop: 15 }} gutter={[16, 16]}>
                    <Col span={24}>
                      <DoughnutChart
                        chart={{
                          height: 200,
                          margin: [30, 0, 50, 0],
                          legend: {
                            align: 'center',
                            layout: 'horizontal',
                            verticalAlign: 'bottom',
                          },
                        }}
                        data={{
                          key: 'issues',
                          seriesName: 'issues',
                          value: [
                            {
                              name: 'critical',
                              color: '#eb3d34',
                              y: _.get(
                                this.state.totalServiceCount,
                                'critical'
                              ),
                            },
                            {
                              name: 'major',
                              color: '#e28931',
                              y: _.get(this.state.totalServiceCount, 'major'),
                            },
                            {
                              name: 'minor',
                              color: '#e2eb34',
                              y: _.get(this.state.totalServiceCount, 'minor'),
                            },
                          ],
                        }}
                        click={(val) =>
                          this.props.history.push(`/issues?priority=${val}`)
                        }
                        loaded={!this.state.isLoadingIssuesAndInsights}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            )}
            <Col span={this.state.currentType === 'vehicles' ? 4 : 6}>
              <PMCountWidget
                isLoadingPMCounts={this.state.isLoadingPMCounts}
                pmCounts={this.state.pmCounts}
                handlePMsCountClick={this.handlePMsCountClick}
              />
              <div style={{ marginTop: 16 }} />
              <SafetyInspectionsCountWidget
                isLoading={this.state.isLoadingPMCounts}
                counts={this.state.pmCounts}
                handleClick={this.handleSafetyInspectionsCountClick}
              />
            </Col>
            <Col span={6}>
              <DVIRCountWidget
                dvirValues={this.state.dvirValues}
                isLoaded={!this.state.isLoadingDvir}
                onClickAmount={(status) =>
                  this.addFiltersToQuery('dvir', status)
                }
              />
            </Col>
          </Row>
        )}

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <VehiclesTable
              loadTotalAssetsCount={this.loadTotalAssetsCount}
              childRef={(ref) => (this.assetsTableRef = ref)}
              type={this.state.currentType}
              onUpdateCarStatus={() => {
                this.loadVehicleAvailabilityAndShopStatuses();
              }}
            />
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

export default withRouter(observer(CarsPage));
