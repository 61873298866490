import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { observe } from 'mobx';

import { AlertsOverview, CriticalIssues } from '../reports';
import { DealershipChooser } from 'components';
import { DoughnutChart, WidgetToolTip } from 'shared/PitstopUI/PitstopChart';
import DateRangeBasedTitle from 'components/PriorityVehicles/DateRangeBasedTitle';
import { ReportsDataStore, CurrentUserStore, ShopStore, AppStore } from 'stores';
import DateRangeSelect from '../DateRangeSelect';
import DateRangeEnum from 'helpers/DateRangeEnum';
import { errors } from 'helpers';

const DateRangeSelectWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const { Text } = Typography;

class HighPriorityVehiclesDashboard extends React.Component {
  static propTypes = {
    history: PropTypes.object,
  };

  state = {
    dateRangeValue: DateRangeEnum.SEVEN_DAYS,
    loaded: ReportsDataStore.maintenanceSummaryInRange.loaded
  }
  load = async () => {
    try {
      if (this.shopId !== -1) {
        ReportsDataStore.getDrivingReport(this.shopId);
        ReportsDataStore.getMaintenanceSummaryReport(this.shopId);
        ReportsDataStore.refreshMaintenanceSummaryReportInRange(
          moment().tz(CurrentUserStore?.user?.settings?.timezone || 'America/Toronto').subtract(ReportsDataStore.currentSelectedDateRangeDays, 'days').startOf('day'),
          moment().tz(CurrentUserStore?.user?.settings?.timezone || 'America/Toronto').endOf('day'),
        );
        ReportsDataStore.getAlarmReport(this.shopId);
        //ReportsDataStore.getSystemWatcherReports(this.shopId);
        ReportsDataStore.getVehiclesScoreReport(this.shopId);
        ReportsDataStore.getVehicleHealthScoreReport(this.shopId);
        ReportsDataStore.getEngineIdleScoreReport(this.shopId);
        ReportsDataStore.getHighPriorityVehicles(ReportsDataStore.currentSelectedDateRangeDays, 20);
        ReportsDataStore.getRoIReport(this.shopId);
        ReportsDataStore.getPredictiveInsightReport(this.shopId);
        ReportsDataStore.getVehicleCostStatsReport(this.shopId);
      }
    } catch (err) {
      errors.logErrorWithoutAlert(err, 'Error in getting report data!');
    }
  };
  disposer = observe(ShopStore.currentShop, 'id', async () => {
    if (ShopStore.currentShop.id !== -1) {
      this.setState({ loadingFleetSettings: true }, async () => {
        await this.getShopSettings(this.shopId);
        this.load();
        this.setState({ loadingFleetSettings: false });
      });
    }
  });

  componentDidMount(){
    AppStore.setSelectedSidebarKey('/highpriorityvehicles');
    ReportsDataStore.reload();
  }

  getShopSettings = async () => {
    let { result } = await ShopStore.getShopSettings(this.shopId);

    if (
      _.isEmpty(result) ||
      _.isNil(result[0].settings) ||
      _.isNil(result[0].settings.reportLayout)
    )
      return;

    if (!_.isEmpty(result[0].settings.reportLayout)) {
      this.setState({
        layout: result[0].settings.reportLayout,
      });
    }
  };

  componentWillUnmount() {
    this.disposer();
  }

  onChangeDateRange = (value) => {
    ReportsDataStore.setCurrentSelectedDateRange(value);
    this.setState({
      dateRangeValue: value,
    });
    let startDate, endDate, lookbackDays;
    const userTimeZone = CurrentUserStore?.user?.settings?.timezone || 'America/Toronto';
    switch (value) {
      case DateRangeEnum.ONE_DAY:
        startDate = moment().tz(userTimeZone).subtract(1, 'days').startOf('day');
        endDate = moment().tz(userTimeZone).endOf('day');
        lookbackDays = 1;
        break;
      case DateRangeEnum.THREE_DAYS:
        startDate = moment().tz(userTimeZone).subtract(3, 'days').startOf('day');
        endDate = moment().tz(userTimeZone).endOf('day');
        lookbackDays = 3;
        break;
      case DateRangeEnum.SEVEN_DAYS:
        startDate = moment().tz(userTimeZone).subtract(7, 'days').startOf('day');
        endDate = moment().tz(userTimeZone).endOf('day');
        lookbackDays = 7;
        break;
      case DateRangeEnum.ONE_MONTH:
        startDate = moment().tz(userTimeZone).subtract(1, 'months').startOf('day');
        endDate = moment().tz(userTimeZone).endOf('day');
        lookbackDays = 30;
        break;
      case DateRangeEnum.THREE_MONTHS:
        startDate = moment().tz(userTimeZone).subtract(3, 'months').startOf('day');
        endDate = moment().tz(userTimeZone).endOf('day');
        lookbackDays = 90;
        break;
      default:
        startDate = moment().tz(userTimeZone).subtract(7, 'days').startOf('day');
        endDate = moment().tz(userTimeZone).endOf('day');
        lookbackDays = 7;
        break;
    }
    ReportsDataStore.refreshMaintenanceSummaryReportInRange(startDate, endDate);
    ReportsDataStore.getHighPriorityVehicles(lookbackDays, 20);
  }

  render(){
    return(
      <>
        <DealershipChooser />
        <DateRangeSelectWrapper>
            <Text strong style={{ marginRight: '10px' }}>Date Range:</Text>
            <DateRangeSelect
              defaultValue={this.state.dateRangeValue}
              value={this.state.dateRangeValue}
              onChange={this.onChangeDateRange}
            />
          </DateRangeSelectWrapper>
        <Row gutter={[16, 16]}>
            <Col xl={{ span: 9 }} xxl={{ span: 6 }}>
              <Card
                bodyStyle={{ padding: '24px 12px' }}
                style={{ height: 'fit-content' }}
              >
                <DateRangeBasedTitle titlePrefix='Fleet Maintenance Summary' />
                <WidgetToolTip title='This shows the breakdown of all issues across all vehicles within the fleet' colour='black' />

                {(
                  <DoughnutChart
                    chart={{
                      height: 400,
                      margin: [30, 0, 50, 0],
                      legend: {
                        align: 'center',
                        layout: 'horizontal',
                        verticalAlign: 'bottom',
                      },
                    }}
                    data={{
                      key: 'issues',
                      seriesName: 'issues',
                      value:
                        [
                              {
                                name: 'critical',
                                color: '#eb3d34',
                                y: _.get(
                                  ReportsDataStore.totalServiceCountInRange,
                                  'critical'
                                ),
                              },
                              {
                                name: 'major',
                                color: '#e28931',
                                y: _.get(
                                  ReportsDataStore.totalServiceCountInRange,
                                  'major'
                                ),
                              },
                              {
                                name: 'minor',
                                color: '#e2eb34',
                                y: _.get(
                                  ReportsDataStore.totalServiceCountInRange,
                                  'minor'
                                ),
                              },
                            ],
                    }}
                    click={(val) =>
                      this.props.history.push(`/issues?priority=${val}`)
                    }
                    loaded={
                      (ReportsDataStore.maintenanceSummaryInRange.loaded)
                    }
                  />
                )}
              </Card>
            </Col>

            <Col xl={{ span: 15 }} xxl={{ span: 18 }}>
              <Card>
                <AlertsOverview />
              </Card>
            </Col>
          </Row>
          <Row style={{ marginBottom: '20px' }}>
            <Col>
              <Card>
                <CriticalIssues dateRangeValue={this.state.dateRangeValue} />
              </Card>
            </Col>
          </Row>
      </>
    );
  }
}

export default withRouter(observer(HighPriorityVehiclesDashboard));