import { observable, action, decorate } from 'mobx';

const getOrientation = () => {
  if (window.matchMedia('(orientation: portrait)').matches) {
    return 'portrait';
  } else if (window.matchMedia('(orientation: landscape)').matches) {
    return 'landscape';
  }
};

const isMobile = () => {
  const lIsMobile = (
    /Mobi|Android|iPhone|iPod/i.test(navigator.userAgent) ||
    window.innerWidth < 600
  );
  // even if it's mobile, if on landscape, return false
  const lIsLandscape = getOrientation() === 'landscape';
  return lIsMobile && !lIsLandscape;
};

const isTablet = () => {
  // console.log('isTablet', window.innerWidth);
  // console.log('orientation', getOrientation());
  const lIsTablet =
    /iPad|Tablet/i.test(navigator.userAgent) ||
    (window.innerWidth >= 600 && window.innerWidth < 912);
  const lIsLandscape = getOrientation() === 'landscape';
  return lIsTablet && !lIsLandscape; // landscape tablet is desktop
};

const getDeviceType = () => {
  let currentDeviceType = 'desktop';
  if (isTablet()) {
    currentDeviceType = 'tablet';
  } else if (isMobile()) {
    currentDeviceType = 'mobile';
  }

  let currentDevice = {
    device: currentDeviceType,
    orientation: isTablet() || isMobile() ? getOrientation() : null,
  };

  return currentDevice;
};

class DeviceStore {
  device = 'desktop';
  orientation = null;

  constructor() {
    this.updateDeviceType();
    window.addEventListener('resize', this.updateDeviceType);
    window.addEventListener('orientationchange', this.updateDeviceType);
  }

  updateDeviceType = () => {
    const { device, orientation } = getDeviceType();
    this.device = device;
    this.orientation = orientation;
  };

  dispose() {
    window.removeEventListener('resize', this.updateDeviceType);
    window.removeEventListener('orientationchange', this.updateDeviceType);
  }
}

decorate(DeviceStore, {
  device: observable,
  orientation: observable,
  updateDeviceType: action,
});

const deviceStore = new DeviceStore();
export default deviceStore;
