import {
  transaction,
  action,
  decorate,
  observable,
  computed,
  autorun,
} from 'mobx';
import _ from 'lodash';

import { TableStore, AbstractStore } from 'stores/abstract';
import {
  AppStore,
  ShopStore,
  UserStore,
  CurrentUserStore,
  WorkOrderStore,
} from 'stores';
import {
  IntegrationCacheStore,
  IssuesTableCacheStore,
  PitstopTableCacheStore,
  ReportCacheStore,
  HighPriorityCarsCacheStore,
} from 'stores/CacheStore';

import { CarObject, Logger } from 'stores/Classes/';

import { webServiceProvider } from 'shared';
import { errors } from 'helpers';

class CarStoreC extends AbstractStore {
  getCarsForUserPending = false;
  getCarsForUserLoaded = false;

  userCarsTable = new TableStore();
  demoCarsTable = new TableStore();

  driverList = new Map();
  demo = null;

  carsFetchController = new AbortController();
  carsCheckFetchController = new AbortController();
  carsBasicInfoFetchController = new AbortController();
  engineLightStatusController = new AbortController();
  shopRealCarsCountAbortController = new AbortController();
  nextPmAbortController = new AbortController();
  carsHighPriorityController = new AbortController();
  assignDemoCarController = new AbortController();
  unassignDemoCarController = new AbortController();

  storeCurrentAssetType = 'vehicles';

  get currentAssetType () {
    return this.storeCurrentAssetType;
  }

  setCurrentAssetType = (type) => {
    this.storeCurrentAssetType = type;
  };

  get hasDemoCars () {
    let demoCars = _.filter(this.demoCarsTable.data, (id) => {
      let car = this.data.get(id);

      return car && car.vin.includes('DEMO-');
    });

    return !_.isEmpty(demoCars);
  }

  get hasRealCars () {
    let realCars = _.filter(this.demoCarsTable.data, (id) => {
      let car = this.data.get(id);

      return car && !car.vin.includes('DEMO-');
    });

    return !_.isEmpty(realCars);
  }

  toggleDemo = () => {
    this.demo = !this.demo;
  };

  reaction = autorun(async () => {
    if (this.loaded) {
      if (this.hasDemoCars) {
        this.demo = true;
      }

      if (this.hasRealCars) {
        this.demo = null;
      }
    }
  });

  assignDemoCar = async (shopId = ShopStore.currentShop.id) => {
    try {
      await this.assignDemoCarController.abort();
      this.assignDemoCarController = new AbortController();
      this.assignDemoCarSignal = this.assignDemoCarController.signal;

      await webServiceProvider.post(
        `v1/demo/cars/${shopId}`,
        {},
        this.assignDemoCarSignal
      );
    } catch (err) {
      errors.response(err);
    }
  };

  unassignDemoCar = async (shopId = ShopStore.currentShop.id) => {
    try {
      await webServiceProvider.delete(`v1/demo/cars/${shopId}`);
    } catch (err) {
      Logger.error(err);
      // AppStore.addError(err.message);
    }
  };

  updateCarStatusOnView = async (car, status) => {
    try {
      this.data.set(car.id_car, {
        ...car,
        car_status: status,
      });
    } catch (err) {
      throw new Error(
        `${err.message || 'Error in updating vehicle information!'}`
      );
    }
  };

  updateCar = async (car, property) => {
    try {
      let { message } = await webServiceProvider.put(
        'v1/car/' + car.id,
        property
      );

      transaction(() => {
        if (message === 'success') {
          Object.keys(property).forEach((key) => (car[key] = property[key]));
        } else {
          throw new Error(message);
        }
      });
    } catch (err) {
      throw new Error(
        `${err.message || 'Error in updating vehicle information!'}`
      );
    }
  };

  updateScannerForCar = async (car, property) => {
    try {
      await webServiceProvider.put(`v1/car/${car.id}/scanner`, property);
      Object.keys(property).forEach((el) => {
        Object.assign(car, { scanner: { scannerId: property[el] } });
      });
      return 'success';
    } catch (err) {
      errors.response(
        err,
        'Error updating the device, make sure its not an empty string'
      );
    }
  };

  updateCarStatusOnServer = async (car, status) => {
    try {
      await webServiceProvider.put(`v1/car/${car.id_car || car.id}/status`, {
        status,
        userId:
          CurrentUserStore.user.id !== -1 ? CurrentUserStore.user.id : 8830,
      });
      return 'success';
    } catch (err) {
      errors.response(err, 'Error updating the status, try again later');
    }
  };

  addCar = async (data) => {
    await webServiceProvider.post('v1/car', data);

    if (!_.isNil(CarStore.demo)) {
      await this.unassignDemoCar();
    }

    PitstopTableCacheStore.reset();
    IssuesTableCacheStore.reset();
    IntegrationCacheStore.reset();
    ReportCacheStore.reset();

    this.demoCarsTable.reset();
    this.reset();
  };

  fetchDVIRsForCars = async (carIds, isTrailers = false) => {
    try {
      if (!carIds) {
        return;
      }
      let endpoint = isTrailers
        ? `v1/dvirs/latest?carIds=${carIds}&trailers=true`
        : `v1/dvirs/latest?carIds=${carIds}`;
      const { data } = await webServiceProvider.get(endpoint);
      return data;
    } catch (err) {
      Logger.error(err);
    }
  };

  fetchDVIRsByShopId = async (shopId) => {
    try {
      if (!shopId || shopId < 1) {
        return;
      }
      const { data } = await webServiceProvider.get(
        `v1/dvirs/shop?shopId=${shopId}`
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  fetchSamsaraUsers = async (shopId) => {
    try {
      if (!shopId) {
        return;
      }
      const { data } = await webServiceProvider.post(
        'v1/integrations/samsara/list-shop-users',
        {
          shopId,
        }
      );
      return data;
    } catch (err) {
      console.error(err);
      return err;
    }
  };

  resolveDVIR = async (payload) => {
    try {
      if (!payload) {
        return;
      }
      const { recordId, samsaraUserEmail, mechanicNotes, shopId } = payload;
      const { data } = await webServiceProvider.post(
        'v1/integrations/samsara/dvir/resolve',
        {
          recordId,
          samsaraUserEmail,
          shopId,
          mechanicNotes,
        }
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  resolveDVIRDefect = async (payload) => {
    try {
      if (!payload) {
        return;
      }
      const {
        dvirId,
        defectId,
        mechanicNotes,
        isResolved,
        resolvedBySamsaraUserId,
        shopId,
      } = payload;
      const { data } = await webServiceProvider.post(
        'v1/integrations/samsara/dvir/resolve-defect',
        {
          dvirId,
          defectId,
          mechanicNotes,
          isResolved,
          resolvedBySamsaraUserId,
          shopId,
        }
      );
      return data;
    } catch (err) {
      throw err;
    }
  };

  /**
   *
   * @param {object} payload
   * @param {number} payload.shopId
   * @param {number} payload.dvirId
   * @param {number} payload.resolvedBySamsaraUserId
   * @param {string} payload.mechanicNotes
   * @param {Array<object>} payload.defects
   */
  resolveDVIRDefects = async (payload) => {
    try {
      if (!payload) {
        return;
      }
      if (!payload.defects || !payload.defects.length) {
        return;
      }
      // console.log(payload);
      const defectsResolved = [];
      for (const defect of payload.defects) {
        try {
          const data = await this.resolveDVIRDefect({
            dvirId: payload.dvirId,
            defectId: defect.id,
            mechanicNotes: payload.mechanicNotes,
            isResolved: true,
            resolvedBySamsaraUserId: payload.resolvedBySamsaraUserId,
            shopId: payload.shopId,
          });
          defectsResolved.push(data);
        } catch (error) {
          console.error('Error resolving defect', error);
        }
      }
      return defectsResolved;
    } catch (err) {
      throw err;
    }
  };

  saveMechanicNotes = async (payload) => {
    try {
      if (!payload) {
        return;
      }
      const { data } = await webServiceProvider.post(
        'v1/integrations/samsara/dvir/save-mechanic-notes',
        payload
      );
      return data;
    } catch (err) {
      throw err;
    }
  };

  getShopCarsForTable = async (
    { offset, limit, filter, sort, sortPm, sortSafetyPm },
    tableStore = new TableStore(),
    shopId = ShopStore.currentShop.id,
    extraSettings = {
      isAddNextPmSummary: true,
      isAddWorkOrder: true,
      isAddDvirData: true,
      isAddEngineLightStatus: false,
      isReturnMetaAndData: false,
    }
  ) => {
    if (_.isNil(shopId)) {
      throw Error('shopId is undefined');
    }
    try {
      this.pending = true;
      this.loaded = false;

      tableStore.loaded = false;
      tableStore.pending = true;

      let _filter = filter || tableStore.filter;

      let params = {
        ...(shopId !== -1 && { shopId }),
        ..._filter,
        offset: _.isNumber(offset) && offset > -1 ? offset : tableStore.offset,
        limit: _.isNumber(limit) && limit > -1 ? limit : tableStore.limit,
      };

      if (sort) {
        params.sort = sort;
      }

      if (sortPm) {
        params.sortPm = sortPm;
      }

      if (sortSafetyPm) {
        params.sortSafetyPm = sortSafetyPm;
      }

      let { data, meta } = await this.getCarsForTable(
        params,
        extraSettings.isAddWorkOrder,
        extraSettings.isAddNextPmSummary,
        extraSettings.isAddDvirData,
        extraSettings.isAddEngineLightStatus
      );

      tableStore.data = [];

      _.forEach(data, (item) => {
        let carObj = new CarObject(item);
        let carId = Number(item.id);

        this.data.set(carId, carObj);
        tableStore.data.push(carId);
      });
      tableStore.setDataMetadata(meta.count, offset, limit, sort, filter);

      this.loaded = true;

      if (extraSettings && extraSettings.isReturnMetaAndData) {
        return {
          data,
          meta,
          offset,
          limit,
          sort,
          filter,
        };
      }
      return data;
    } catch (err) {
      // errors.response(err, 'Unable to get cars by shop');
    } finally {
      this.pending = false;
    }
  };

  getShopAssetsCountSummary = async (shopId = null) => {
    try {
      if (!shopId || shopId === -1) {
        return;
      }
      const { result } = await webServiceProvider.get(
        `v1/car?shopId=${shopId}&scope=count`
      );
      return result;
    } catch (err) {
      errors.response(err, 'Unable to get shop assets count summary');
    }
  };

  getShopCars = async (
    { offset, limit, filter, sort },
    shopId = ShopStore.currentShop.id
  ) => {
    if (_.isNil(shopId)) {
      throw Error('shopId is undefined');
    }

    try {
      this.pending = true;
      this.loaded = false;

      let _filter = filter;

      let params = {
        offset: offset,
        limit: limit,
        ...(shopId !== -1 && { shopId }),
        ..._filter,
      };

      if (sort) {
        params.sort = sort;
      }

      return this.getCarsForTable(params);
    } catch (err) {
      // errors.response(err, 'Unable to get cars by shop');
    } finally {
      this.pending = false;
    }
  };

  getUserCarsForTable = async (
    { offset, limit, filter, sort, sortPm, sortSafetyPm },
    tableStore,
    userId,
    shopId = ShopStore.currentShop.id,
    extraSettings = {
      isAddNextPmSummary: true,
      isAddWorkOrder: true,
      isAddDvirData: true,
      isAddEngineLightStatus: false,
      isReturnMetaAndData: false,
    }
  ) => {
    if (_.isNil(userId)) {
      throw Error('User is undefined');
    }

    try {
      this.pending = true;
      this.loaded = false;
      tableStore.pending = true;
      tableStore.loaded = false;

      let _filter = filter || tableStore.filter;

      let params = {
        offset: offset || tableStore.offset,
        limit: limit || tableStore.limit,
        ...(shopId !== -1 && { shopId }),
        userId,
        ..._filter,
      };

      if (sort) {
        params.sort = sort;
      }

      if (sortPm) {
        params.sortPm = sortPm;
      }

      if (sortSafetyPm) {
        params.sortSafetyPm = sortSafetyPm;
      }

      let { data, meta } = await this.getCarsForTable(
        params,
        true,
        extraSettings.isAddNextPmSummary,
        extraSettings.isAddDvirData,
        extraSettings.isAddEngineLightStatus
      );

      tableStore.data = [];

      _.forEach(data, (item) => {
        let carObj = new CarObject(item);
        let carId = Number(item.id);

        this.data.set(carId, carObj);
        tableStore.data.push(carId);
      });

      tableStore.setDataMetadata(meta.count, offset, limit, sort, filter);
      this.userCarsTable = tableStore;
      if (extraSettings && extraSettings.isReturnMetaAndData) {
        return {
          data,
          meta,
          offset,
          limit,
          sort,
          filter,
        };
      }
      return data;
    } catch (err) {
      errors.response(err, 'Unable to get cars by user');
    } finally {
      // this.pending = false;
    }
  };

  getUserCars = async (
    { offset, limit, filter, sort },
    userId,
    shopId = ShopStore.currentShop.id
  ) => {
    if (_.isNil(userId)) {
      throw Error('User is undefined');
    }

    try {
      this.pending = true;
      this.loaded = false;

      let _filter = filter;

      let params = {
        offset: offset,
        limit: limit,
        ...(shopId !== -1 && { shopId }),
        userId,
        ..._filter,
      };

      if (sort) {
        params.sort = sort;
      }

      return this.getCarsForTable(params);
    } catch (err) {
      errors.response(err, 'Unable to get cars by user');
    } finally {
      // this.pending = false;
    }
  };

  getDriverHistory = async (
    { offset, limit, sort } = {},
    tableStore,
    carId
  ) => {
    tableStore.pending = true;
    tableStore.loaded = false;
    offset = offset === undefined ? tableStore.offset : offset;
    limit = limit === undefined ? tableStore.limit : limit;
    sort = sort === undefined ? tableStore.sort : sort;
    try {
      const params = {
        offset: offset / limit,
        limit,
      };

      if (sort) {
        params.sort = sort;
      } else {
        sort = undefined;
      }

      const { data } = await webServiceProvider.getMany(
        `v1/car/${carId}/driverhistory`,
        params
      );

      transaction(() => {
        tableStore.data.clear();
        for (let i = 0; i < data.length; i++) {
          this.driverList.set(offset * limit + i, {
            id: offset * limit + i,
            ...data[i],
          });
          tableStore.data.push(offset * limit + i);
        }
        //TODO change 0 to pagelimit once pagination has been implemented
        tableStore.setDataMetadata(0 * limit, offset, limit, sort);
      });
    } catch (err) {
      errors.response(err, 'Unable to load campaigns.');
    }
  };

  fetchEngineLightStatusForCars = async (carIds = []) => {
    try {
      if (!carIds || !carIds.length) {
        return;
      }
      const data = await webServiceProvider.get(
        `v1/car/cars/multiple-engine-light-status?carIds=${JSON.stringify(
          carIds
        )}`
      );
      return data;
    } catch (err) {
      Logger.error(err);
    }
  };

  getCarsForTable = async (
    params,
    isAddWorkOrder = false,
    isAddNextPmSummary = false,
    isAddDvirData = false,
    isAddEngineLightStatus = false
  ) => {
    try {
      this.carsFetchController.abort();

      this.carsFetchController = new AbortController();
      this.carsFetchSignal = this.carsFetchController.signal;

      let { result: data, meta } = await webServiceProvider.getMany(
        'v1/car',
        params,
        this.carsFetchSignal
      );

      if (isAddNextPmSummary) {
        const carIds = _.map(data, 'id');
        if (!_.isEmpty(carIds)) {
          const pmSchedules = await this.getPmScheduleSummaries(
            carIds.join(',')
          );
          // foreach car, set next pm visit
          data = _.map(data, (car) => {
            const nextPm = _.find(pmSchedules, { carId: car.id });
            if (nextPm) {
              car.nextPm = nextPm;
            }
            return car;
          });
        }
      }

      if (isAddWorkOrder) {
        const carIds = _.map(data, 'id');
        if (!_.isEmpty(carIds)) {
          const workOrders = await this.getCarsWorkOrders(carIds.join(','));
          // foreach car, set next pm visit
          data = _.map(data, (car) => {
            const workOrdersAssigned = _.filter(workOrders, { id_car: car.id });
            if (workOrdersAssigned && workOrdersAssigned.length) {
              car.workOrdersAssigned = workOrdersAssigned;
            }
            return car;
          });
        }
      }

      if (isAddDvirData) {
        const carIds = _.map(data, 'id');
        if (!_.isEmpty(carIds)) {
          const dvirsData = await this.fetchDVIRsForCars(carIds.join(','));
          // hydrate car with dvir data
          data = data.map((item) => {
            const dvir = dvirsData.find((dvir) => dvir.carId === item.id);
            return {
              ...item,
              dvir,
            };
          });
        }
      }

      if (isAddEngineLightStatus) {
        const carIds = _.map(data, 'id');
        if (!_.isEmpty(carIds)) {
          const engineLightStatusesResponse = await this.fetchEngineLightStatusForCars(
            carIds
          );
          const engineLightStatuses = engineLightStatusesResponse?.data;
          // hydrate car with currentEngineLightStatus data
          data = data.map((item) => {
            const currentEngineLightStatus = engineLightStatuses.find(
              (currentEngineLightStatus) =>
                currentEngineLightStatus.id_car === item.id
            );
            return {
              ...item,
              currentEngineLightStatus: currentEngineLightStatus
                ? currentEngineLightStatus
                : 'not-found',
            };
          });
        }
      }

      _.forEach(data, (item) => {
        if (item.userId && !UserStore.data.has(item.userId)) {
          UserStore.addUserToStore(Number(item.userId), item.user);
        }
      });

      return { data, meta };
    } catch (err) {
      throw err;
    }
  };

  getCarsForCSV = async (params) => {
    try {
      let { result: data, meta } = await webServiceProvider.getMany(
        'v1/car',
        params,
        this.carsFetchSignal
      );
      await this.addDvirData(data, params);
      if (!params.trailers) {
        await this.addEngineLightStatus(data);
      }
      return { data, meta };
    } catch (err) {
      return { error: true, err };
    }
  };

  addDvirData = async (data, params) => {
    const carIds = _.map(data, 'id');
    const isTrailers = params.trailers || false;
    if (!_.isEmpty(carIds)) {
      const dvirsData = await this.fetchDVIRsForCars(
        carIds.join(','),
        isTrailers
      );
      // Attach DVIR data to cars
      data.forEach((car) => {
        const dvir = dvirsData.find((dvir) => dvir.carId === car.id);
        car.dvir = dvir;
      });
    }
  };

  addEngineLightStatus = async (data) => {
    const carIds = _.map(data, 'id');
    if (!_.isEmpty(carIds)) {
      const engineLightStatusesResponse = await this.fetchEngineLightStatusForCars(
        carIds
      );
      const engineLightStatuses = engineLightStatusesResponse?.data;

      data.forEach((car) => {
        const currentEngineLightStatus = engineLightStatuses.find(
          (status) => status.vin === car.vin
        );
        car.currentEngineLightStatus = currentEngineLightStatus || 'not-found';
      });
    }
  };

  getDemoCarsForTable = async (shopId = ShopStore.currentShop.id) => {
    try {
      this.pending = true;
      this.loaded = false;

      this.demoCarsTable.reset();
      this.demoCarsTable.loaded = false;
      this.demoCarsTable.pending = true;

      let params = {
        offset: 0,
        limit: 21,
        // we have 20 demos in db so if there are more than 20 vehicles in the fleet,
        // it means a real car has been added from backend
        ...(shopId !== -1 && { shopId }),
      };

      const { result: data } = await webServiceProvider.getMany(
        'v1/car',
        params
      );

      _.forEach(data, (item) => {
        if (item.userId && !UserStore.data.has(item.userId)) {
          UserStore.addUserToStore(Number(item.userId), item.user);
        }

        let carObj = new CarObject(item);
        let carId = Number(item.id);

        this.data.set(carId, carObj);
        this.demoCarsTable.data.push(carId);
      });

      this.demoCarsTable.setDataMetadata(this.demoCarsTable.data.length);

      this.loaded = true;
      this.pending = false;
    } catch (err) {
      throw err;
    }
  };

  getHighPriorityTableByCarsIds = async (
    { offset, limit, filter, sort },
    tableStore,
    shopId = ShopStore.currentShop.id,
    carIds
  ) => {
    try {
      this.carsHighPriorityController.abort();

      this.carsHighPriorityController = new AbortController();

      let _filter = filter || tableStore.filter;

      let params = {
        offset: offset || tableStore.offset,
        limit: limit || tableStore.limit,
        ...(shopId !== -1 && { shopId }),
        ..._filter,
        carIds: carIds && carIds.length ? carIds.join(',') : 'null',
      };

      if (sort) {
        params.sort = sort;
      }

      tableStore.loaded = false;
      tableStore.pending = true;

      // check if we have all the data in the cache
      const cacheKey = `${ShopStore.currentShop.id
        }-getHighPriorityTableByCarsIds-${JSON.stringify(params)}`;
      const cachedReturn = HighPriorityCarsCacheStore.getData(cacheKey);
      // if cached return exists, return it
      let data, meta;
      if (cachedReturn) {
        data = cachedReturn.data;
        meta = cachedReturn.meta;
      } else {
        const response = await webServiceProvider.getMany(
          'v1/car',
          params,
          this.carsHighPriorityController.signal
        );
        data = response.result;
        meta = response.meta;
        HighPriorityCarsCacheStore.setData(cacheKey, { data, meta });
      }

      _.forEach(data, (item) => {
        let carObj = new CarObject(item);
        let carId = Number(item.id);

        this.data.set(carId, carObj);
        tableStore.data.push(carId);
      });

      tableStore.setDataMetadata(meta.count, offset, limit, filter, sort);

      this.loaded = true;
    } catch (err) {
      errors.response(err, 'Unable to get vehicles!');
    }
  };

  getHighPriorityTable = async (
    { offset, limit, filter, sort },
    tableStore,
    shopId = ShopStore.currentShop.id
  ) => {
    try {
      this.carsHighPriorityController.abort();

      this.carsHighPriorityController = new AbortController();
      this.carsHighPrioritySignal = this.carsHighPriorityController.signal;

      let _filter = filter || tableStore.filter;

      let params = {
        offset: offset || tableStore.offset,
        limit: limit || tableStore.limit,
        ...(shopId !== -1 && { shopId }),
        ..._filter,
      };

      if (sort) {
        params.sort = sort;
      }

      tableStore.loaded = false;
      tableStore.pending = true;

      const { result: data, meta } = await webServiceProvider.getMany(
        'v1/car',
        params,
        this.carsHighPrioritySignal
      );
      _.forEach(data, (item) => {
        let carObj = new CarObject(item);
        let carId = Number(item.id);

        this.data.set(carId, carObj);
        tableStore.data.push(carId);
      });
      tableStore.setDataMetadata(meta.count, offset, limit, filter, sort);

      this.loaded = true;
    } catch (err) {
      errors.response(err, 'Unable to get vehicles!');
    }
  };

  getCarsForUser = async (
    { limit },
    userId,
    shopId = ShopStore.currentShop.id
  ) => {
    try {
      this.getCarsForUserPending = true;
      this.getCarsForUserLoaded = false;

      let params = {
        userId,
        ...(shopId !== -1 ? { shopId } : null),
      };

      if (limit) {
        params.limit = limit;
      }

      let { result: returnedCars, meta } = await webServiceProvider.getMany(
        'v1/car',
        params
      );
      if (!Array.isArray(returnedCars)) {
        returnedCars = [];
      }

      let i = 0;
      let idArray = [];
      for (i = 0; i < returnedCars.length; i++) {
        this.data.set(returnedCars[i].id, new CarObject(returnedCars[i]));
        idArray.push(returnedCars[i].id);
      }

      if (UserStore.data.has(userId)) {
        let user = UserStore.data.get(userId);

        user.cars.replace(idArray);
        user.totalCarsAssociated = meta.count;
      }
      if (CurrentUserStore.user.userId === userId) {
        CurrentUserStore.user.cars.replace(idArray);
      }

      this.getCarsForUserLoaded = true;
    } catch (error) {
      AppStore.addError('Error fetching car data for user');
    } finally {
      this.getCarsForUserPending = false;
    }
  };

  getCarById = async (id) => {
    try {
      let returnedCar = await webServiceProvider.getById('v1/car', id);
      this.data.set(returnedCar.id, new CarObject(returnedCar));
      if (returnedCar.shop && !ShopStore.data.has(returnedCar.shop.id)) {
        ShopStore.addShopToStore(returnedCar.shop.id, returnedCar.shop);
      }
      if (returnedCar.userId) {
        if (!UserStore.data.has(returnedCar.userId)) {
          await UserStore.fetchUser(returnedCar.userId, false);
        }
        UserStore.data.get(returnedCar.userId).cars.push(id);
      }
      // load data sync status
    } catch (err) {
      errors.response(err, `Error fetching car data with id ${id}`);
    }
  };

  setCarData = (data, carId) => {
    this.data.set(carId, new CarObject(data));
  };

  setCarDataKey = (carId, key, value) => {
    this.data.get(carId)[key] = value;
  };

  getShopVehicleAvailabilitySummary = async (shopId, contentType) => {
    try {
      const response = await webServiceProvider.get(
        `v1/shop/${shopId}/vehicle-availability?carType=${contentType}`
      );
      return response;
    } catch (err) {
      errors.response(err, `Error fetching car data with id ${shopId}`);
    }
  };

  getShopCarByVin = async (shopId = ShopStore.currentShop.id, vin) => {
    try {
      const response = await webServiceProvider.get(
        `v1/car?shopId=${shopId}&exclude=issueList&vin=${vin}&offset=0&limit=10`
      );
      return response;
    } catch (error) {
      errors.response(error, `Error fetching car data with id ${shopId}`);
    }
  };

  getShopPMStatusSummary = async (shopId) => {
    if (
      shopId === undefined ||
      shopId === null ||
      shopId === '' ||
      shopId === -1 ||
      shopId === '-1'
    ) {
      return;
    }
    try {
      const response = await webServiceProvider.get(`shop/${shopId}/pm-status`);
      return response;
    } catch (err) {
      errors.response(err, `Error fetching car data with id ${shopId}`);
    }
  };

  getShopPMStatusCounts = async (shopId) => {
    try {
      const response = await webServiceProvider.get(
        `v1/shop/${shopId}/pm-counts`
      );
      return response;
    } catch (err) {
      errors.response(err, `Error fetching car data with id ${shopId}`);
    }
  };

  getShopTrailersPMStatusCounts = async (shopId) => {
    try {
      const response = await webServiceProvider.get(
        `v1/shop/${shopId}/pm-counts?trailers=true`
      );
      return response;
    } catch (err) {
      errors.response(err, `Error fetching car data with id ${shopId}`);
    }
  };

  updateCarPM = async (carId, issue) => {
    try {
      const response = await webServiceProvider.post(`v1/car/${carId}/pm`, {
        issue,
      });
      return response;
    } catch (err) {
      throw new Error(err);
    }
  };

  getCarByIdAndReturn = async (id) => {
    try {
      let returnedCar = await webServiceProvider.getById('v1/car', id);
      return returnedCar;
    } catch (err) {
      errors.response(err, `Error fetching car data with id ${id}`);
    }
  };

  getEngineLightStatus = async (carId) => {
    try {
      this.engineLightStatusController = new AbortController();
      const { result } = await webServiceProvider.getMany(
        `v1/car/${carId}/engine-light-status`,
        {},
        this.engineLightStatusController.signal
      );
      return result;
    } catch (err) {
      errors.response(
        err,
        `Error fetching engine light status for car ${carId}`
      );
    }
  };

  getCarsBasicInfoAndReturn = async (carIds) => {
    try {
      this.carsBasicInfoFetchController = new AbortController();
      const { result } = await webServiceProvider.getMany(
        'v1/car',
        {
          carIds,
          shopId: ShopStore.currentShop.id,
        },
        this.carsBasicInfoFetchController.signal
      );
      return result;
    } catch (err) {
      errors.response(err, `Error fetching car data with ids ${carIds}`);
    }
  };

  getShopCarsBasicInfoAndReturn = async (shopId) => {
    try {
      if (this.carsBasicInfoFetchController.signal) {
        this.carsBasicInfoFetchController.abort();
      }
      this.carsBasicInfoFetchController = new AbortController();
      const { result } = await webServiceProvider.getMany(
        'v1/car',
        {
          shopId,
          scope: 'basicInfo',
        },
        this.carsBasicInfoFetchController.signal
      );
      return result;
    } catch (err) {
      errors.response(err, `Error fetching cars for shop ${shopId} and return. Please, try again later.`);
    }
  };

  getShopCarsBasicInfo = async (shopId) => {
    try {
      const result = await webServiceProvider.getMany(
        'v1/car',
        {
          shopId,
          scope: 'basicInfo',
        }
      );
      return result;
    } catch (err) {
      errors.response(err, `Error fetching cars for shop ${shopId}`);
    }
  };

  getShopCarsBasicInfo = async (shopId) => {
    try {
      const result = await webServiceProvider.getMany(
        'v1/car',
        {
          shopId,
          scope: 'basicInfo',
        }
      );
      return result;
    } catch (err) {
      errors.response(err, `Error fetching car data with ids ${shopId}`);
    }
  };

  getOrCreateCar = (carId) => {
    if (!this.data.has(Number(carId))) {
      this.data.set(Number(carId), new CarObject({ id: Number(carId) }));
    }
    return this.data.get(Number(carId));
  };

  createTrailer = async (carData) => {
    try {
      const trailerData = await webServiceProvider.post('v1/trailer', carData);
      return trailerData;
    } catch (err) {
      errors.response(err, 'Error creating trailer');
    }
  };

  createGenericAsset = async (carData) => {
    try {
      const assetData = await webServiceProvider.post(
        'v1/car/createGeneric',
        carData
      );
      return assetData;
    } catch (err) {
      errors.response(err, 'Error creating generic asset');
    }
  };

  archiveAssets = async ({
    assetIds = [],
    notes = '',
    shopId = ShopStore.currentShop.id,
  }) => {
    try {
      await webServiceProvider.post('v1/car/archive', {
        assetIds,
        notes,
        shopId,
      });
    } catch (err) {
      throw err;
    }
  };

  restoreAssets = async ({ assetIds = [], notes = '', shopId }) => {
    try {
      await webServiceProvider.post('v1/car/restore', {
        assetIds,
        notes,
        shopId,
      });
    } catch (err) {
      throw err;
    }
  };

  async checkIfShopHasCars (shopId) {
    if (!shopId) {
      throw new Error('shop not set');
    }

    let key = `${shopId}-has-cars`;

    if (IntegrationCacheStore.getData(key) !== undefined) {
      return IntegrationCacheStore.getData(key);
    }

    try {
      await this.carsCheckFetchController.abort();

      this.carsCheckFetchController = new AbortController();
      this.carsCheckFetchSignal = this.carsCheckFetchController.signal;

      const { data } = await webServiceProvider.get(
        `v1/car/shop/${shopId}/has-cars`,
        this.carsCheckFetchSignal
      );

      IntegrationCacheStore.setData(key, data);

      return data;
    } catch (err) {
      errors.response(err, 'Sorry! We are unable to check if shop has cars!');
    }
  }

  async countShopRealCars (shopId) {
    if (!shopId) {
      throw new Error('shop not set');
    }

    try {
      await this.shopRealCarsCountAbortController.abort();

      this.shopRealCarsCountAbortController = new AbortController();

      const { data } = await webServiceProvider.get(
        `v1/car/shop/${shopId}/real-cars-count`,
        this.shopRealCarsCountAbortController.signal
      );

      return data;
    } catch (err) {
      errors.response(err, 'Sorry! We are unable to count shop real cars!');
    }
  }

  /**
   *
   * @param {String} carIds - comma separated car ids
   * @returns
   */
  async getPmScheduleSummaries (carIds) {
    if (!carIds) {
      Logger.warn('carIds not set');
      return;
    }

    try {
      await this.nextPmAbortController.abort();

      this.nextPmAbortController = new AbortController();

      const { data } = await webServiceProvider.get(
        `v1/pm-schedule-summaries?carIds=${carIds}`,
        this.nextPmAbortController.signal
      );

      return data;
    } catch (err) {
      errors.response(
        err,
        'Sorry! We are unable to fetch for PM Schedule Summaries.'
      );
    }
  }

  /**
   * Update car notes
   */
  updateCarNotes = async (carId, notes) => {
    try {
      await webServiceProvider.put(`v1/cars/${carId}/notes`, { notes });
    } catch (err) {
      errors.response(err, 'Sorry! We are unable to update car notes.');
    }
  };

  /**
   *
   * @param {String} carIds - comma separated car ids
   * @returns
   */
  async getCarsWorkOrders (carIds) {
    if (!carIds) {
      Logger.warn('carIds not set');
      return;
    }

    try {
      const response = await WorkOrderStore.searchWorkOrders({
        carIds,
        statusIn:
          'pending,open,in_progress,blocked_for_parts,waiting_for_approval',
      });

      return response;
    } catch (err) {
      Logger.error(err);
    }
  }

  async getCarDocuments (carId) {
    try {
      if (!carId) {
        return;
      }
      const response = await webServiceProvider.get(
        `v1/car/${carId}/documents`
      );
      return response;
    } catch (err) {
      Logger.error(err);
    }
  }

  async upsertCarDocuments (carId, documents = []) {
    try {
      if (!carId) {
        return;
      }
      const response = await webServiceProvider.put(
        `v1/car/${carId}/documents`,
        {
          documents,
        }
      );
      return response;
    } catch (err) {
      Logger.error(err);
    }
  }
}

decorate(CarStoreC, {
  demo: observable,
  userCarsTable: observable,
  demoCarsTable: observable,
  getCarsForUserPending: observable,
  getCarsForUserLoaded: observable,
  getOrCreateCar: action,
  getDemoCarsForTable: action,
  getShopCarsForTable: action,
  getUserCarsForTable: action,
  getEngineLightStatus: action,
  getDriverHistory: action,
  getCarsForUser: action,
  unassignDemoCar: action,
  updateCar: action,
  toggleDemo: action,
  getHighPriorityTable: action,
  addCar: action,
  hasDemoCars: computed,
  hasRealCars: computed,
  currentAssetType: computed,
  setCurrentAssetType: action,
  countShopRealCars: action,
  getPmScheduleSummaries: action,
  updateCarNotes: action,
});

const CarStore = new CarStoreC();
export default CarStore;
