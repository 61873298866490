import styled from 'styled-components';

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-top: 5px;
  }

  .btn-status {
    color: white;

    :hover {
      border: 1px solid #1890ff;
      color: white;
    }
  }
`;

export default StatusWrapper;
