import React, { Component, Fragment } from 'react';
import { observe } from 'mobx';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import PurchaseOrdersPageHeader from 'components/PurchaseOrders/PurchaseOrdersPageHeader';
import PurchaseOrdersTable from 'components/PurchaseOrders/PurchaseOrdersTable';
import {
  CurrentUserStore,
  ShopStore,
  PurchaseOrderStore,
  WorkOrderStore,
  ContactStore,
  UserStore,
  AppStore,
} from 'stores';
import { DealershipChooser } from 'components';

import { notification, Row, Col, Input, Button, Tag } from 'antd';
import styled from 'styled-components';

const { Search } = Input;

const FiltersWrapperRow = styled(Row)`
  .filter-tag {
    padding: 2px 15px;
    background: #ffffff;
    border-radius: 1rem;
  }
`;

class PurchaseOrdersPage extends Component {
  state = {
    loading: true,
    shopPurchaseOrders: [],
    shopParts: [],
    shopVendors: [],
    shopUsers: [],
    currentSearch: '',
    currentStatusFilters: [],
  };

  shopChangeWatcher = observe(ShopStore.currentShop, 'id', () => {
    if (ShopStore.isAdmin()) return;
    return this.init();
  });

  componentDidMount() {
    AppStore.setSelectedSidebarKey('/purchase-orders');
    this.init();
  }

  componentWillUnmount() {
    this.shopChangeWatcher();
  }

  async componentDidUpdate(unusedPrevProps, prevState) {
    let { currentSearch } = this.state;
    if (currentSearch !== prevState.currentSearch) {
      this.handleSearchChange();
    }
  }

  searchDebouncer = null;

  handleSearchChange = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.searchDebouncer);
    this.searchDebouncer = setTimeout(() => {
      this.reloadPurchaseOrders();
    }, 500);
  };

  init = async () => {
    this.setState({ loading: true });
    const purchaseOrders = await PurchaseOrderStore.fetchShopPurchaseOrders(
      ShopStore.currentShop.id
    );
    this.setState({
      shopPurchaseOrders: (purchaseOrders || []).map((po) => {
        return {
          ...po,
          loadingVendors: true,
          loadingParts: true,
          loadingUsers: true,
        };
      }),
      loading: false,
    });
    this.getShopVendors();
    this.getShopParts();
    this.getShopUsers();
  };

  reloadPurchaseOrders = async () => {
    this.setState({ loading: true });
    const filters = {
      search: this.state.currentSearch,
      limit: 100,
      offset: 0,
    };
    if (this.state.currentStatusFilters.length) {
      filters.status = this.state.currentStatusFilters.join(',');
    }
    const shopPurchaseOrders = await PurchaseOrderStore.fetchShopPurchaseOrders(
      ShopStore.currentShop.id,
      filters
    );
    this.setState({ shopPurchaseOrders, loading: false });
  };

  getShopParts = async () => {
    const shopParts = await WorkOrderStore.getShopParts(
      ShopStore.currentShop.id,
      {
        include_deleted: true,
      }
    );
    this.setState({
      shopParts,
      shopPurchaseOrders: this.state.shopPurchaseOrders.map((po) => {
        return {
          ...po,
          loadingParts: false,
        };
      }),
    });
  };

  getShopVendors = async () => {
    const shopVendors = await ContactStore.getShopContacts(
      ShopStore.currentShop.id
    );
    this.setState({
      shopVendors,
      shopPurchaseOrders: this.state.shopPurchaseOrders.map((po) => {
        return {
          ...po,
          loadingVendors: false,
        };
      }),
    });
  };

  getShopUsers = async () => {
    const shopUsers = await UserStore.fetchShopUsers(ShopStore.currentShop.id);
    this.setState({
      shopUsers,
      shopPurchaseOrders: this.state.shopPurchaseOrders.map((po) => {
        return {
          ...po,
          loadingUsers: false,
        };
      }),
    });
  };

  deletePurchaseOrder = async (row) => {
    this.setState({ loading: true });
    await PurchaseOrderStore.deletePurchaseOrder(row.id);
    notification.success({
      message: 'Success',
      description: `${
        ShopStore.currentShop.id === 374 ? 'Invoice' : 'Purchase Order'
      } Deleted`,
    });
    this.init();
  };

  onChangeStatus = async (data) => {
    this.setState({ loading: true });
    await PurchaseOrderStore.savePurchaseOrder({
      id: data.rowId,
      status: data.status,
    });
    notification.success({
      message: 'Success',
      description: 'Status Updated',
    });
    this.reloadPurchaseOrders();
    this.setState({ loading: false });
  };

  render() {
    return (
      <Fragment>
        <PurchaseOrdersPageHeader
          onClickAdd={() => {
            this.props.history.push('/purchase-orders/add');
          }}
          title={`${
            ShopStore.currentShop.id === 374 ? 'Invoices' : 'Purchase Orders'
          }`}
        />
        {CurrentUserStore.isAdmin && <DealershipChooser />}
        <Row style={{ marginBottom: 10, marginTop: 10 }} gutter={[16, 16]}>
          <Col span={6}>
            <Search
              placeholder="Search by #"
              onChange={(event) => {
                this.setState({
                  currentSearch: event.target.value,
                });
              }}
            />
          </Col>
        </Row>
        {/* Filter badges */}
        <FiltersWrapperRow style={{ marginBottom: 10 }} gutter={[16, 16]}>
          <Col span={24}>
            {this.state.currentStatusFilters.length === 0 ? (
              <Button disabled size="small" style={{ marginRight: 10 }}>
                No filters selected
              </Button>
            ) : (
              <Button
                size="small"
                style={{ marginRight: 10 }}
                type="primary"
                onClick={() => {
                  this.setState(
                    {
                      currentStatusFilters: [],
                    },
                    () => {
                      this.reloadPurchaseOrders();
                    }
                  );
                }}
              >
                Clear all filters
              </Button>
            )}
            {/* Add badges with current selected filters here */}
            {this.state.currentStatusFilters.map((filter) => {
              return (
                <Tag
                  key={filter}
                  className="filter-tag"
                  closable
                  onClose={() => {
                    this.setState(
                      {
                        currentStatusFilters: this.state.currentStatusFilters.filter(
                          (f) => f !== filter
                        ),
                      },
                      () => {
                        this.reloadPurchaseOrders();
                      }
                    );
                  }}
                >
                  {filter === 'open' ? 'Open' : 'Posted'}
                </Tag>
              );
            })}
          </Col>
        </FiltersWrapperRow>
        <PurchaseOrdersTable
          loading={this.state.loading}
          data={this.state.shopPurchaseOrders}
          shopVendors={this.state.shopVendors}
          shopParts={this.state.shopParts}
          shopUsers={this.state.shopUsers}
          onChangeTable={(data, other) => {
            if (data.statusChange) {
              return this.onChangeStatus(data);
            }
            const toUpdateState = {};
            if (other.status && other.status.length) {
              toUpdateState.currentStatusFilters = other.status;
            } else if (other.status) {
              toUpdateState.currentStatusFilters = [];
            }
            this.setState(toUpdateState, () => {
              this.reloadPurchaseOrders();
            });
          }}
          onDeletePurchaseOrder={(row) => {
            this.deletePurchaseOrder(row);
          }}
        />
      </Fragment>
    );
  }
}

PurchaseOrdersPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(PurchaseOrdersPage);
