import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { decorate } from 'mobx';
import _ from 'lodash';

import {
  Col,
  Row,
  List,
  Typography,
  Skeleton,
  Select,
  InputNumber,
  Icon,
  Button,
  Popover,
  Tooltip,
} from 'antd';

import { convertToMonetary } from 'shared/utils';
import FormLegend from 'components/Forms/FormLegend';
import WorkOrderSummary from './WorkOrderSummary';
import WorkOrderLineItemsSummary from './LineItems/WorkOrderLineItemsSummary';
import WorkOrderDetailsContext from 'containers/WorkOrder/WorkOrderDetailsContext';
import { CurrentUserStore, ShopStore } from 'stores';

const { Text } = Typography;
const { Option } = Select;

// border: solid 1px #ddd;
const Wrapper = styled.div``;

const CustomList = styled(List)`
  .ant-list-item {
    padding: 10px 75px;

    @media (max-width: 768px) {
      padding: 10px 0;
    }

    > div {
      font-weight: bold;
      min-width: 100px;

      @media (max-width: 768px) {
        min-width: 40px;
      }

      text-align: right;
    }

    .ant-list-item-meta {
      .ant-list-item-meta-content {
        text-align: left;
      }
    }
  }
`;

const CustomListItem = styled.div`
  margin-left: -17px;
  .ant-typography {
    padding-left: 5px;
  }
`;

class WorkOrderCostSummary extends Component {
  static contextType = WorkOrderDetailsContext;

  static propTypes = {
    isEdit: PropTypes.bool,
    refs: PropTypes.any,
  };

  state = {
    tax: {},
    taxOptions: [],
  };

  componentDidMount() {
    if (this.props.refs) {
      this.props.refs(this);
    }
    if (!this.props.isEdit) {
      this.onLoadSettings();
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   const currentShopId = ShopStore.currentShop.id;
  //   const previousShopId = prevState.shopId;

  //   if (currentShopId !== previousShopId) {
  //     this.onLoadSettings();
  //   }
  // }

  async onLoadSettings() {
    const { id } = ShopStore.currentShop;

    const shopSettings = await ShopStore.getShopTaxSettings(id);

    const { state } = this.context;
    if (shopSettings) {
      const newTax = {
        type: shopSettings.taxType || state.initialFormValues.tax.type,
        value: shopSettings.taxValue || state.initialFormValues.tax.value,
      };

      state.initialFormValues.tax = newTax;
      this.setState({
        taxOptions: shopSettings.taxOptions || [],
        tax: newTax,
      });
    } else {
      const newTax = {
        type: state.initialFormValues.tax.type,
        value: state.initialFormValues.tax.value,
      };

      state.initialFormValues.tax = newTax;
      this.setState({
        taxOptions: [],
        tax: newTax,
      });
    }
  }

  get calculatedLaborsTotal() {
    const {
      state: {
        importedInformation,
        currentWorkOrder,
        lineItems,
        laboursTotalHasManualOverride,
        manualOverrides: { manualLaboursTotal },
      },
    } = this.context;

    if (laboursTotalHasManualOverride) {
      return manualLaboursTotal;
    }

    let laborTotal = 0;
    if (currentWorkOrder?.laborsTotal) {
      laborTotal = currentWorkOrder.laborsTotal;
    }
    if (importedInformation) {
      const objImportedInfo = JSON.parse(importedInformation);
      if (
        objImportedInfo &&
        objImportedInfo['Labour Cost'] &&
        objImportedInfo['Labour Cost'] !== 'NOT FOUND'
      ) {
        laborTotal = parseFloat(objImportedInfo['Labour Cost']);
      }
    }
    // if there is at least one labour in the lineItems, it'll always use it
    if (lineItems.length) {
      const labours = lineItems.map((l) => l.labours);
      // remove all empty arrays
      const allLabours = _.flatten(labours);
      if (allLabours.length) {
        laborTotal = _.sumBy(allLabours, (labour) => {
          return parseFloat(labour.cost || 0) * parseFloat(labour.hours || 0);
        });
      }
    }

    if (!laborTotal || !laborTotal.toFixed) {
      return 0;
    }

    // force it to always be 2 decimal places
    laborTotal = parseFloat(laborTotal.toFixed(2));

    return laborTotal;
  }

  get calculatedPartsTotal() {
    const {
      state: {
        importedInformation,
        currentWorkOrder,
        lineItems,
        partsTotalHasManualOverride,
        manualOverrides: { manualPartsTotal },
      },
    } = this.context;

    if (partsTotalHasManualOverride) {
      return manualPartsTotal;
    }

    let partsTotal = 0;
    if (currentWorkOrder?.partsTotal) {
      partsTotal = currentWorkOrder.partsTotal;
    }
    if (importedInformation) {
      const objImportedInfo = JSON.parse(importedInformation);
      if (
        objImportedInfo &&
        objImportedInfo['Parts Cost'] &&
        objImportedInfo['Parts Cost'] !== 'NOT FOUND'
      ) {
        partsTotal = parseFloat(objImportedInfo['Parts Cost']);
      }
    }
    // if there is at least one part in the lineItems, it'll always use it
    if (lineItems.length) {
      const parts = lineItems.map((l) => l.parts);
      // remove all empty arrays
      const allParts = _.flatten(parts);
      if (allParts.length) {
        partsTotal = _.sumBy(allParts, (part) => {
          const cost = part.cost ? parseFloat(part.cost) : 0;
          const markup = part.markup ? parseFloat(part.markup) : 0;
          const amount = part.amount ? parseInt(part.amount) : 0;
          return (cost + (cost * markup) / 100) * amount;
        });
      }
    }

    if (!partsTotal || !partsTotal.toFixed) {
      return 0;
    }

    // force it to always be 2 decimal places
    partsTotal = parseFloat(partsTotal.toFixed(2));

    return partsTotal;
  }

  get calculatedMiscellaneousTotal() {
    const {
      state: {
        importedInformation,
        currentWorkOrder,
        lineItems,
        miscellaneousTotalHasManualOverride,
        manualOverrides: { manualMiscellaneousTotal },
      },
    } = this.context;

    if (miscellaneousTotalHasManualOverride) {
      return manualMiscellaneousTotal;
    }

    let miscellaneousTotal = 0;
    if (currentWorkOrder?.miscellaneousTotal) {
      miscellaneousTotal = currentWorkOrder.miscellaneousTotal;
    }

    if (importedInformation) {
      const objImportedInfo = JSON.parse(importedInformation);
      if (
        objImportedInfo &&
        objImportedInfo['Miscellaneous Cost'] &&
        objImportedInfo['Miscellaneous Cost'] !== 'NOT FOUND'
      ) {
        miscellaneousTotal = parseFloat(objImportedInfo['Miscellaneous Cost']);
      }
    }

    // if there is at least one miscellaneous in the lineItems, it'll always use it
    if (lineItems.length) {
      const miscs = lineItems.map((l) => l.miscellaneous);
      // remove all empty arrays
      const allMiscs = _.flatten(miscs);
      if (allMiscs.length) {
        miscellaneousTotal = _.sumBy(allMiscs, (misc) => {
          const cost = misc.unit_cost ? parseFloat(misc.unit_cost) : 0;
          const quantity = misc.quantity ? parseFloat(misc.quantity) : 0;

          return cost * quantity;
        });
      }
    }

    if (!miscellaneousTotal || !miscellaneousTotal.toFixed) {
      return 0;
    }

    // force it to always be 2 decimal places
    miscellaneousTotal = parseFloat(miscellaneousTotal.toFixed(2));

    return miscellaneousTotal;
  }

  get summaryItems() {
    const {
      state: {
        initialFormValues: { tax, misc },
        laboursTotalHasManualOverride,
        partsTotalHasManualOverride,
        miscellaneousTotalHasManualOverride,
      },
      onChangeTaxType,
      onChangeTaxValue,
      onChangeMiscType,
      onChangeMiscValue,
      onChangeLabourTotal,
      onChangePartsTotal,
      onResetLabourTotalOverride,
      onResetPartsTotalOverride,
    } = this.context;

    return [
      {
        description: 'Labors',
        amount: this.calculatedLaborsTotal,
        extraFields: [
          // we should allow users to override the labor total
          <>
            <InputNumber
              value={this.calculatedLaborsTotal}
              onChange={onChangeLabourTotal}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              style={{
                width: '140px',
                marginRight: '30px',
                marginLeft: '5px',
              }}
            />
            {laboursTotalHasManualOverride && (
              <Popover
                content={
                  <Button
                    icon="sync"
                    size="small"
                    onClick={onResetLabourTotalOverride}
                  >
                    Reset
                  </Button>
                }
                title="The cost has been manually updated. This amount will be different than the individual line items."
                trigger="hover"
              >
                <Icon
                  type="exclamation-circle"
                  style={{
                    color: 'red',
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                />
              </Popover>
            )}
          </>,
        ],
      },
      {
        description: 'Parts',
        amount: this.calculatedPartsTotal,
        extraFields: [
          // we should allow users to override the parts total
          <>
            <InputNumber
              value={this.calculatedPartsTotal}
              onChange={onChangePartsTotal}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              style={{
                width: '140px',
                marginRight: '30px',
                marginLeft: '5px',
              }}
            />
            {partsTotalHasManualOverride && (
              <Popover
                content={
                  <Button
                    icon="sync"
                    size="small"
                    onClick={onResetPartsTotalOverride}
                  >
                    Reset
                  </Button>
                }
                title="The cost has been manually updated. This amount will be different than the individual line items."
                trigger="hover"
              >
                <Icon
                  type="exclamation-circle"
                  style={{
                    color: 'red',
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                />
              </Popover>
            )}
          </>,
        ],
      },
      {
        description: 'Others (Miscellaneous)',
        amount: this.calculatedMiscellaneousTotal,
        extraFields: [
          // we should allow users to override the parts total
          <>
            <InputNumber
              disabled={true}
              value={this.calculatedMiscellaneousTotal}
              onChange={() => {
                // TODO: implement this
              }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              style={{
                width: '140px',
                marginRight: '30px',
                marginLeft: '5px',
              }}
            />
            {miscellaneousTotalHasManualOverride && (
              <Popover
                content={
                  <Button
                    icon="sync"
                    size="small"
                    onClick={() => {
                      // TODO: implement this
                    }}
                  >
                    Reset
                  </Button>
                }
                title="The cost has been manually updated. This amount will be different than the individual line items."
                trigger="hover"
              >
                <Icon
                  type="exclamation-circle"
                  style={{
                    color: 'red',
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                />
              </Popover>
            )}
          </>,
        ],
      },
      {
        description: (
          <>
            <Tooltip
              placement="left"
              title={
                'Tax is calculated based on the criteria you set (e.g. Labor, parts os misc) in the tax settings under the profile tab.'
              }
            >
              <CustomListItem>
                <Icon type="info-circle" />
                <Text>Tax</Text>
              </CustomListItem>
            </Tooltip>
          </>
        ),
        amount: tax.value,
        amountType: tax.type,
        extraFields: [
          <Select
            value={tax.type || 'absolute'}
            onChange={onChangeTaxType}
            key="type"
          >
            <Option key="percentage" value="percentage">
              %
            </Option>
            <Option key="absolute" value="absolute">
              $
            </Option>
          </Select>,
          <InputNumber
            key="value"
            value={tax.value || 0}
            onChange={onChangeTaxValue}
            formatter={(value) =>
              `${tax.type === 'absolute' ? '$ ' : ''}${value}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              )
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{
              width: '100px',
              marginRight: '30px',
              marginLeft: '5px',
            }}
          />,
        ],
      },
      {
        description: 'Misc',
        amount: misc.value,
        amountType: misc.type || 'absolute',
        extraFields: [
          <Select
            value={misc.type || 'absolute'}
            onChange={onChangeMiscType}
            key="type"
          >
            <Option key="percentage" value="percentage">
              %
            </Option>
            <Option key="absolute" value="absolute">
              $
            </Option>
          </Select>,
          <InputNumber
            key="value"
            value={misc.value || 0}
            onChange={onChangeMiscValue}
            formatter={(value) =>
              `${misc.type === 'absolute' ? '$ ' : ''}${value}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              )
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{
              width: '100px',
              marginRight: '30px',
              marginLeft: '5px',
            }}
          />,
        ],
      },
    ];
  }

  get total() {
    const {
      state: {
        lineItems,
        initialFormValues: { tax, misc },
        importedInformation,
        currentWorkOrder,
        costsTotalHasManualOverride,
        manualOverrides: { manualCostsTotal },
      },
    } = this.context;

    if (costsTotalHasManualOverride) {
      return manualCostsTotal;
    }

    let costsTotal = 0;
    if (currentWorkOrder?.costsTotal) {
      costsTotal = currentWorkOrder.costsTotal;
    }
    if (importedInformation) {
      const objImportedInfo = JSON.parse(importedInformation);
      if (
        objImportedInfo &&
        objImportedInfo['Total Cost'] &&
        objImportedInfo['Total Cost'] !== 'NOT FOUND'
      ) {
        costsTotal = parseFloat(objImportedInfo['Total Cost']);
      }
    }
    // if there are line items, it'll always use it
    if (lineItems.length) {
      costsTotal = 0;
      if (tax.type === 'percentage') {
        costsTotal = _.sum([
          this.addTaxValueOnTotalItem(this.calculatedLaborsTotal, tax, 'Labor'),
          this.addTaxValueOnTotalItem(this.calculatedPartsTotal, tax, 'Parts'),
          this.addTaxValueOnTotalItem(
            this.calculatedMiscellaneousTotal,
            tax,
            'Miscellaneous'
          ),
        ]);
      } else {
        costsTotal = _.sum([
          this.calculatedLaborsTotal,
          this.calculatedPartsTotal,
          this.calculatedMiscellaneousTotal,
        ]);
      }

      if (!this.state.taxOptions || this.state.taxOptions.length === 0) {
        const taxValue =
          tax.type === 'percentage'
            ? ((tax.value || 0) / 100) * costsTotal
            : parseFloat(tax.value || 0);
        costsTotal += taxValue;
      }

      const miscValue =
        misc.type === 'percentage'
          ? ((misc.value || 0) / 100) * costsTotal
          : parseFloat(misc.value || 0);
      costsTotal += miscValue;
    }

    if (!costsTotal || !costsTotal.toFixed) {
      return 0;
    }

    // force it to always be 2 decimal places
    costsTotal = parseFloat(costsTotal.toFixed(2));

    return costsTotal;
  }

  addTaxValueOnTotalItem = (value, tax, item) => {
    if (!this.state.taxOptions.includes(item)) return value;

    if (tax.type === 'percentage') {
      return value * (1 + (tax.value || 0) / 100);
    } else {
      return value + parseFloat(tax.value || 0);
    }
  };

  toPercentage(value = 0) {
    return value.toLocaleString('en-US', {
      style: 'percent',
    });
  }

  render() {
    const {
      state: { lineItems, isLaborCostDiscrepancy, isPartsCostDiscrepancy },
      shopUsers,
      shopParts,
      currentCarIssues,
    } = this.context;

    const showExtraField = (item) => {
      if (item.extraFields && item.extraFields.length) {
        return item.extraFields.map((field) => field);
      }
      return <></>;
    };

    const showCurrencyOrPct = (item) => {
      if (item.amountType && item.amountType === 'percentage') {
        return <div>{this.toPercentage(item.amount / 100)}</div>;
      }
      return <div>{convertToMonetary(item.amount)}</div>;
    };

    const customStyles = (description) => {
      const warningStyles = { border: 'solid 1px red', marginBottom: '5px' };
      if (
        (description === 'Labors' && isLaborCostDiscrepancy) ||
        (description === 'Parts' && isPartsCostDiscrepancy)
      ) {
        return warningStyles;
      }
    };

    return (
      <Wrapper className="limit-size-tablets">
        <Row gutter={[8, 8]} className="limit-size-tablets">
          {this.context.state.isMobileScreenSize && (
            <>
              <Col span={24}>
                <FormLegend>Work Order Details</FormLegend>
                <WorkOrderSummary />
              </Col>
              <Col span={24}>
                <WorkOrderLineItemsSummary
                  lineItems={lineItems}
                  shopUsers={shopUsers}
                  shopParts={shopParts}
                  currentCarIssues={currentCarIssues}
                />
              </Col>
            </>
          )}
          <Col span={24}>
            <FormLegend>Cost Summary</FormLegend>
          </Col>
          <Col span={24}>
            <CustomList
              footer={
                <List.Item>
                  <List.Item.Meta title={<Text strong>Total</Text>} />
                  {/* Users can override the total... */}
                  <InputNumber
                    value={this.total}
                    onChange={(value) => {
                      this.context.onChangeSummaryTotal(value);
                    }}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    style={{
                      width: '140px',
                      marginRight: '20px',
                    }}
                  />
                  {this.context.state.costsTotalHasManualOverride && (
                    <Popover
                      content={
                        <Button
                          icon="sync"
                          size="small"
                          onClick={this.context.onResetCostsTotalOverride}
                        >
                          Reset
                        </Button>
                      }
                      title="The cost has been manually updated. This amount will be different than the individual line items."
                      trigger="hover"
                    >
                      <Icon
                        type="exclamation-circle"
                        style={{ color: 'red', marginLeft: '10px' }}
                      />
                    </Popover>
                  )}
                  <div
                    style={{
                      marginLeft: '10px',
                    }}
                  >
                    {convertToMonetary(this.total)}
                  </div>
                </List.Item>
              }
              dataSource={this.summaryItems}
              renderItem={(item) => (
                <List.Item style={customStyles(item.description)}>
                  <Skeleton title={false} loading={item.loading} active>
                    <List.Item.Meta title={item.description} />
                    {showExtraField(item)}
                    {showCurrencyOrPct(item)}
                  </Skeleton>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

decorate(WorkOrderCostSummary, {});

export default observer(WorkOrderCostSummary);
