import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { VehicleHealthScore, VehicleScore, VehiclesWithSpecificIssues, VehiclesWithDEFIssues } from '../reports';
import { DealershipChooser } from 'components';
import { AppStore, ShopStore } from 'stores';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const WidgetContainer = styled.div`
  border: solid;
  border-width: 2px;
  border-color: #f9f9f9;
  margin: 5px;
  padding: 12px;
`;

class FleetHealthDashboard extends React.Component{
  componentDidMount() {
    AppStore.setSelectedSidebarKey('/fleethealth');
  }

  render(){
    return(
      <>
        <DealershipChooser />
        <GridContainer>
          <WidgetContainer>
            <h4>Vehicle Health Ranking from Fault Codes</h4>
            <VehicleHealthScore />
          </WidgetContainer>
          <WidgetContainer>
            <h4>Vehicle Health Ranking from Service Records</h4>
            <VehicleScore />
          </WidgetContainer>
          {ShopStore.currentShop.id === 1194 ?
            <WidgetContainer>
              <h4>Vehicles with ABS Alerts</h4>
              <VehiclesWithSpecificIssues />
            </WidgetContainer> : null}
          {ShopStore.currentShop.id === 374 ?
          <WidgetContainer>
            <h3>Vehicles With DEF Alerts</h3>
            <VehiclesWithDEFIssues />
          </WidgetContainer> : null}
        </GridContainer>
      </>

    );
  }

}

export default withRouter(observer(FleetHealthDashboard));