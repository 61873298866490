import { DealershipChooser } from 'components';
import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { decorate, observable } from 'mobx';

import { TableStore, ShopStore, AppStore } from 'stores';

import { AlgorithmStats, BrakeUsage, DefSystemHealth } from '../reports';


const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const WidgetContainer = styled.div`
  border: solid;
  border-width: 2px;
  border-color: #f9f9f9;
  margin: 5px;
  padding: 12px 12px;
`;

class AlgorithmInsightsDashboard extends React.Component{
  componentDidMount() {
    AppStore.setSelectedSidebarKey('/algorithminsights');
  }

  state = {
    defTableStore: new TableStore(),
    brakeTableStore: new TableStore(),
    algorithmStatsTableStore: new TableStore(),
    shopID: ShopStore.currentShop.id
  }

  setDefTableStore = (newStore) => {
    this.setState({defTableStore: newStore});
  };

  setBrakeTableStore = (newStore) => {
    this.setState({brakeTableStore: newStore});
  }

  setAlgorithmStatsTableStore = (newStore) => {
    this.setState({algorithmStatsTableStore: newStore});
  }

  render(){
    if (this.state.shopID !== ShopStore.currentShop.id){
      this.setState({defTableStore: new TableStore(),
        brakeTableStore: new TableStore(),
        algorithmStatsTableStore: new TableStore(),
        shopID: ShopStore.currentShop.id
      });
    }
    return (
      <>
        <DealershipChooser />
        <GridContainer>
          {this.state.brakeTableStore.count > 0 || !this.state.brakeTableStore.loaded ?
          <WidgetContainer>
            <h4>Brake Usage Scores</h4>
            <BrakeUsage setStore={this.setBrakeTableStore}/>
          </WidgetContainer>
          : null}
          {this.state.defTableStore.count > 0 || !this.state.defTableStore.loaded ?
          <WidgetContainer>
            <h4>DEF System Health</h4>
            <DefSystemHealth setStore={this.setDefTableStore} />
          </WidgetContainer>
          : null}
          {this.state.algorithmStatsTableStore.count > 0 || !this.state.algorithmStatsTableStore.loaded ?
            <WidgetContainer>
              <h4>Algorithm Status</h4>
              <AlgorithmStats setStore={this.setAlgorithmStatsTableStore}/>
            </WidgetContainer>
          : null}
          {/* Hiding this chart for now because it seems to have issues loading
            ReportsDataStore.predictiveInsightReport.count > 0 || !ReportsDataStore.loaded ?
            <WidgetContainer>
              <h4>Predictive Insights Breakdown</h4>
              <PredictiveInsights />
            </WidgetContainer>
          : null */}
        </GridContainer>
      </>
    );
  }
}

decorate(AlgorithmInsightsDashboard, {
    defTableStore: observable,
    brakeTableStore: observable,
    algorithmStatsTableStore: observable
  }
);

export default withRouter(observer(AlgorithmInsightsDashboard));