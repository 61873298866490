import React, { Component } from 'react';
import { Tabs } from 'antd';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import PartRepeatVisitsPage from '../PartRepeatVisits/PartRepeatVisitsPage.js'; // Adjust the path as needed
import AppStore from 'stores/App.js';

const { TabPane } = Tabs;

const Container = styled.div`
  max-width: 1300px; // Adjust the width as needed
  margin: 0 auto; // Center the container
  padding: 20px; // Add padding if necessary
`;

class PartReportsPage extends Component {
  componentDidMount() {
    AppStore.setSelectedSidebarKey('/part-reports');
  }

  render() {
    return (
      <Container>
        <Tabs defaultActiveKey="1">
          {/* <TabPane tab="Part Costs" key="1">
            <PartCostsPage />
          </TabPane> */}
          <TabPane tab="Parts Repeat Visits" key="1">
            <PartRepeatVisitsPage />
          </TabPane>
          {/* <TabPane tab="Part Avg Cost Per Visit" key="3">
            <PartAvgCostPerVisitPage />
          </TabPane> */}
        </Tabs>
      </Container>
    );
  }
}

export default withRouter(observer(PartReportsPage));