import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { decorate, observable, observe } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PitstopButton } from 'shared';
import { ShopStore } from 'stores';
import DealershipChooser from 'components/DealershipChooser';
import { Row, Col, Icon, Button } from 'antd';
import PitstopSimpleTable from 'components/PitstopSimpleTable';
import AppStore from 'stores/App';
import LocationFormModal from 'components/ShopLocations/LocationFormModal';
import { get } from 'lodash';
import moment from 'moment';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 25px 0 10px;
`;

const ActionButtonsWrapper = styled.div`
  right: 15px;

  button {
    margin-left: 10px;
  }
`;

class ShopLocationsPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
  };

  shopId = ShopStore.currentShop.id;
  LOCATION_FORM_MODAL_ID = 'location_form_modal_id';

  state = {
    savingLocation: false,
    loading: false,
    data: [],
    locationModalTitle: 'Add Location',
    selectedLocationData: {},
  };

  columns = [
    {
      title: 'Location Code',
      dataIndex: 'code',
      key: 'code',
      render: (text, record) => (
        <span onClick={() => this.onClickEdit(record)} style={{ cursor: 'pointer' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Location Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <>
            <Button onClick={() => this.onClickEdit(record)}>Edit</Button>
            <Button onClick={() => this.onDeleteLocation(record)}>Delete</Button>
          </>
        );
      },
    },
  ];

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    return this.refreshLocations(ShopStore.currentShop.id);
  });

  componentWillUnmount() {
    this.disposer();
  }

  componentDidMount() {
    this.refreshLocations();
  }

  onClickAddLocation = () => {
    this.setState(
      {
        locationModalTitle: 'Add Location',
        selectedLocationData: {},
      },
      () => {
        AppStore.openModals.set(this.LOCATION_FORM_MODAL_ID, true);
      }
    );
  };

  onClickEdit = async (location) => {
    this.setState(
      {
        locationModalTitle: 'Edit Location',
        selectedLocationData: location,
      },
      () => {
        AppStore.openModals.set(this.LOCATION_FORM_MODAL_ID, true);
      }
    );
  };

  refreshLocations = async (shopId = ShopStore.currentShop.id) => {
    try {
      await this.setState({ loading: true });
      // locations are saved in shop settings for now
      const shopSettings = await ShopStore.getShopSettings(shopId);
      const locations = get(shopSettings, 'result[0].settings.locations', []);
      await this.setState({ data: locations });
    } catch (error) {
      console.log(error);
      AppStore.addError('Error loading locations: ' + error.message);
    } finally {
      this.setState({ loading: false });
    }
  };

  onSubmitLocationForm = async (data) => {
    try {
      await this.setState({ savingLocation: true });
      // locations are saved in shop settings for now
      const shopSettings = await ShopStore.getShopSettings(ShopStore.currentShop.id);
      const locations = get(shopSettings, 'result[0].settings.locations', []);
      // if id is present, update the existing Location
      const existingLocation = locations.find(
        (location) => location.id === data.id
      );
      let updatedLocations;
      if (existingLocation) {
        // update the existing Location
        updatedLocations = locations.map((location) =>
          location.id === data.id ? data : location
        );
      } else {
        // otherwise, add a new Location
        updatedLocations = [
          ...locations,
          {
            id: data.id || moment().unix(),
            code: data.code,
            description: data.description,
          },
        ];
      }
      await ShopStore.upsertShopSettings(ShopStore.currentShop.id, {
        ...get(shopSettings, 'result[0].settings', {}),
        locations: updatedLocations,
      });
      AppStore.addSuccess('Location saved successfully');
      await this.refreshLocations();
      this.setState({ selectedLocationData: {} });
      AppStore.openModals.set(this.LOCATION_FORM_MODAL_ID, false);
    } catch (error) {
      console.log(error);
      AppStore.addError('Error saving location: ' + error.message);
    } finally {
      await this.setState({ savingLocation: false });
    }
  };

  onDeleteLocation = async (location) => {
    try {
      await this.setState({ savingLocation: true });
      const shopSettings = await ShopStore.getShopSettings(ShopStore.currentShop.id);
      const locations = get(shopSettings, 'result[0].settings.locations', []);
      const updatedLocations = locations.filter(
        (l) => l.id !== location.id
      );
      await ShopStore.upsertShopSettings(ShopStore.currentShop.id, {
        ...get(shopSettings, 'result[0].settings', {}),
        locations: updatedLocations,
      });
      await this.refreshLocations();
      AppStore.addSuccess('Location deleted successfully');
    } catch (error) {
      console.log(error);
      AppStore.addError('Error deleting location: ' + error.message);
    } finally {
      await this.setState({ savingLocation: false });
    }
  };

  render() {
    return (
      <Fragment>
        <LocationFormModal
          modalId={this.LOCATION_FORM_MODAL_ID}
          saving={this.state.savingLocation}
          title={this.state.locationModalTitle}
          defaultData={this.state.selectedLocationData}
          onCancel={() => {
            this.setState({ selectedLocationData: {} });
            AppStore.openModals.set(this.LOCATION_FORM_MODAL_ID, false);
          }}
          onSubmit={this.onSubmitLocationForm}
        />

        <DealershipChooser />
        <ActionsWrapper>
          <h1>Location Management</h1>
          <ActionButtonsWrapper>
            <PitstopButton icon="plus" onClick={this.onClickAddLocation}>
              Add Location
            </PitstopButton>
          </ActionButtonsWrapper>
        </ActionsWrapper>

        <Row>
          <Col span={24}>
            <PitstopSimpleTable
              loading={this.state.loading}
              columns={this.columns}
              data={this.state.data}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

decorate(ShopLocationsPage, {
  shopId: observable,
});

export default withRouter(observer(ShopLocationsPage));
