import styled from 'styled-components';

const IndicatorTableWrapper = styled.div`
  &&& {
    display: grid;

    table th.indicator,
    table td.indicator {
      position: relative;
      padding: 0;
      width: 8px;
    }
  }

  .filter-tag {
    padding: 2px 15px;
    background: #ffffff;
    border-radius: 1rem;
  }

  .ant-table
    .ant-table-thead
    > tr
    > th
    i.anticon-filter.ant-table-filter-selected {
    background-color: #d9d9d9;
  }
`;

export default IndicatorTableWrapper;
