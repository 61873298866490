import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

//import { PitstopButton } from 'shared';

import ShopStore from 'stores/ShopStore';
import { AppStore } from 'stores';
// import AppointmentCalendar from './AppointmentCalendar';
import ServiceScheduleCalendar from './ServiceScheduleCalendar';

const Wrapper = styled.div`
  position: relative;
`;

/*const NewAppointmentButton = styled(PitstopButton)`
  position: absolute;
  top: 16px;
  right: 0px;
`;*/

class AppointmentCalendarPage extends Component {
  componentDidMount() {
    AppStore.setSelectedSidebarKey('/appointments');
  }

  static propTypes = {
    history: PropTypes.object.isRequired
  };

  render() {
    const shopId = ShopStore.currentShop.id;

    return (
      <Wrapper>
        {/* <NewAppointmentButton
          type="primary"
          icon="plus"
          onClick={() => {
            this.props.history.push('/appointment/create/steps');
          }}
        >
          Create New Appointment
        </NewAppointmentButton> */}
        <h1>Appointment Calendar</h1>
        <ServiceScheduleCalendar
          showDealershipSelection
          ableSlotSelection={false}
          views={['month', 'week', 'day']}
          defaultView="month"
          shopId={shopId}
        />
      </Wrapper>
    );
  }
}

export default withRouter(observer(AppointmentCalendarPage));
