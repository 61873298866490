import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { DealershipChooser } from 'components';
import { FleetWideCostsByMonth } from '../reports';
import { AppStore } from 'stores';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const WidgetContainer = styled.div`
  border: solid;
  border-width: 2px;
  border-color: #f9f9f9;
  margin: 5px;
  padding: 12px;
`;

class DowntimeCostDashboard extends React.Component{
  componentDidMount() {
    AppStore.setSelectedSidebarKey('/downtimecost');
  }

  render(){
    return(
      <>
        <DealershipChooser />
        <GridContainer>
          <WidgetContainer>
            <h4>Cost Breakdown Per Month</h4>
            <FleetWideCostsByMonth />
          </WidgetContainer>
        </GridContainer>
      </>
    );
  }
}

export default withRouter(observer(DowntimeCostDashboard));