import styled from 'styled-components';

const DVIRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tag {
    // cursor: pointer;
    font-size: 16px;
    font-weight: 600;

    .defects-count {
      font-size: 1rem;
      color: #595959;
    }
  }
`;

export default DVIRWrapper;
