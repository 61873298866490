import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ShopStore, CurrentUserStore } from 'stores';
import Timezones from 'components/Timezones.json';
import _ from 'lodash';
import {
  Form,
  Button,
  Select,
  InputNumber,
  notification,
  Row,
  Col,
  Checkbox,
} from 'antd';
import { Typography } from 'antd';

const { Title } = Typography;
const { Option } = Select;
class EditSettingsForm extends Component {
  state = {
    shopId: 0,
    loadingInitial: true,
    shopStores: [],
    loading: false,
    taxType: 'absolute',
    taxValue: 0,
    taxOptions: [],
  };

  async componentDidMount() {
    this.load(this.shopId);
    const { shop = [] } = CurrentUserStore.user;
    this.setState({
      shopId: ShopStore.currentShop.id,
      shopStores: shop.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      }),
    });
  }

  load = async (shopId) => {
    console.log(this.props.form);
    this.setState({
      taxType: '',
      taxValue: 0,
      taxOptions: [],
    });
    try {
      const { result } = await ShopStore.getShopSettings(shopId);
      if (
        !_.isEmpty(result) &&
        !_.isNil(result[0].settings) &&
        !_.isNil(result[0].settings.fleetsettings)
      ) {
        const fleetSettings = result[0].settings.fleetsettings;

        const value = _.get(fleetSettings, 'markAsCompleteThresholdHours', 5);

        this.props.form.setFieldsValue({
          taxType: fleetSettings.taxType,
          taxValue: fleetSettings.taxValue,
          taxOptions: fleetSettings.taxOptions,
        });
        this.setState(
          {
            markAsCompleteThresholdHours: {
              ...this.validateNumber(value),
              value,
            },
            taxType: fleetSettings.taxType,
            taxValue: fleetSettings.taxValue,
            taxOptions: fleetSettings.taxOptions,
          },
          () => {
            this.setState({ loadingInitial: false });
          }
        );
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      this.setState({ loadingInitial: false });
    }
  };

  handleChange = async (v) => {
    this.setState({
      shopId: v,
    });
    this.load(v);
  };

  updateUser = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      const { taxOptions, taxType, taxValue, ...filteredValues } = values;
      this.updateFleetRule(
        {
          taxOptions,
          taxType,
          taxValue,
        },
        values.shopId
      );
      if (!err) {
        this.setState({ loading: true });

        await CurrentUserStore.user.updateSettings(filteredValues);
        this.setState({ loading: false });
        notification.success({
          message: 'Success',
          description: 'Settings Updated',
          duration: 3,
        });
      }
    });
  };

  updateFleetRule = async (values, shopId) => {
    try {
      const settings = await ShopStore.getShopSettings(shopId);
      let currentFleetSettings = _.get(
        settings.result,
        '[0].settings.fleetsettings',
        {}
      );

      currentFleetSettings = { ...currentFleetSettings, ...values };

      _.set(
        settings.result,
        '[0].settings.fleetsettings',
        currentFleetSettings
      );

      await ShopStore.upsertShopSettings(
        this.state.shopId,
        settings.result[0].settings
      );

      notification.success({
        message: 'Rules updated',
        key: 'fleetRulesSuccess',
        description: <p>The Fleet rules were updated!</p>,
      });
    } catch (e) {
    } finally {
    }
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const currentTaxType = getFieldValue('taxType') || 'percentage';

    const taxOptions = ['Parts', 'Miscellaneous', 'Labor'];
    return (
      <React.Fragment>
        <Form onSubmit={this.updateUser} className="edit-profile-form">
          <Title
            style={{
              fontWeight: 'normal',
            }}
            level={3}
          >
            General
          </Title>
          <Form.Item label="Timezone">
            {getFieldDecorator('timezone', {
              rules: [
                {
                  required: true,
                  message: 'Please input your preferred timezone!',
                },
              ],
              initialValue:
                CurrentUserStore.user.settings.timezone || 'America/New_York',
            })(
              <Select showSearch placeholder="Timezone">
                {Object.keys(Timezones).map((key) => (
                  <Select.Option key={key} value={key}>
                    {Timezones[key]}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Title
            style={{
              fontWeight: 'normal',
            }}
            level={3}
          >
            Vehicles
          </Title>
          <Form.Item label="Odometer Unit">
            {getFieldDecorator('odometer', {
              rules: [
                {
                  required: true,
                  message: 'Please input your preferred unit!',
                },
              ],
              initialValue: CurrentUserStore.user.displayedOdometer,
            })(
              <Select placeholder="Odometer Unit">
                <Select.Option value="km">KM</Select.Option>
                <Select.Option value="miles">Miles</Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Fuel Unit">
            {getFieldDecorator('fuelUnit', {
              rules: [
                {
                  required: true,
                  message: 'Please input your preferred unit!',
                },
              ],
              initialValue: CurrentUserStore.user.settings.fuelUnit || 'litre',
            })(
              <Select placeholder="Odometer Unit">
                <Select.Option value="Litre">Litre</Select.Option>
                <Select.Option value="Gallons">Gallons</Select.Option>
              </Select>
            )}
          </Form.Item>

          <Title
            style={{
              fontWeight: 'normal',
            }}
            level={3}
          >
            Work Orders
          </Title>
          <Form.Item label="Set Hourly Labor Rate">
            {getFieldDecorator('laborRate', {
              rules: [],
              initialValue: CurrentUserStore.user.settings.laborRate || '0',
            })(
              <InputNumber
                key="value"
                defaultValue={CurrentUserStore.user.settings.laborRate || 0}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
              />
            )}
          </Form.Item>

          <Row>
            <Col span={24}>
              <Form.Item label={`Select Fleet:`}>
                {getFieldDecorator('shopId', {
                  rules: [],
                  defaultValue: this.state.shopId,
                  initialValue: this.state.shopId,
                })(
                  <Select onChange={this.handleChange}>
                    {this.state.shopStores.map((item) => (
                      <Option key={item.value} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Col span={4}>
                <Form.Item label={'Tax type'} name="taxType">
                  {getFieldDecorator('taxType', {
                    rules: [],
                    initialValue: this.state.taxType,
                  })(
                    <Select
                      placeholder="Tax type"
                      onChange={(e) => {
                        console.log(e);
                        if (e === 'absolute') {
                          this.props.form.setFieldsValue({
                            taxOptions: [],
                          });
                        }
                      }}
                    >
                      <Select.Option value="percentage" key="percentage">
                        %
                      </Select.Option>
                      <Select.Option value="absolute" key="absolute">
                        $
                      </Select.Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Tax value" name="taxValue">
                  {getFieldDecorator('taxValue', {
                    rules: [],
                    initialValue: this.state.taxValue,
                  })(
                    <InputNumber
                      key="value"
                      formatter={(value) =>
                        `${
                          currentTaxType === 'absolute' ? '$ ' : ''
                        }${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      style={{
                        width: '100px',
                        marginRight: '30px',
                        marginLeft: '5px',
                      }}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col span={14}>
                <Form.Item label="Tax options" name="taxOptions">
                  {getFieldDecorator('taxOptions', {
                    rules: [],
                    initialValue: this.state.taxOptions,
                  })(
                    <Checkbox.Group
                      disabled={
                        this.props.form.getFieldValue('taxType') === 'absolute'
                          ? true
                          : false
                      }
                      options={taxOptions}
                      defaultValue={this.state.selectedTaxOptions}
                      onChange={this.handerTaxOptions}
                    />
                  )}
                </Form.Item>
              </Col>
            </Col>
          </Row>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={this.state.loading}
            >
              Update Info
            </Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }
}

EditSettingsForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default Form.create({ name: 'edit-profile-form' })(EditSettingsForm);
