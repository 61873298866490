const nextPMFilterOptions = () => {
  return [
    { text: 'Overdue (100%+)', filterKey: 'Overdue', value: 'overdue' },
    { text: 'Due Soon (80 - 99%)', filterKey: 'Due soon', value: 'dueSoon' },
    /*{ text: 'Upcoming (70 - 79%)', filterKey: 'Upcoming', value: 'upcoming' },*/
    {
      text: 'No action needed (0 - 69%)',
      filterKey: 'No action needed',
      value: 'noActionNeeded',
    },
    {
      text: 'Setup PM',
      filterKey: 'Setup PM',
      value: 'setupPm',
    },
  ];
}

const safetyInspectionsFilterOptions = () => {
  return [
    {
      text: 'Overdue (100%+) - Safety',
      filterKey: 'Overdue',
      value: 'overdue',
    },
    {
      text: 'Due Soon (80 - 99%) - Safety',
      filterKey: 'Due soon',
      value: 'dueSoon',
    },
    {
      text: 'In Compliance (0 - 79%) - Safety',
      filterKey: 'No action needed',
      value: 'noActionNeeded',
    },
  ];
}

const dvirFilterOptions = () => {
  return [
    {
      text: 'Safe',
      value: 'safe',
    },
    {
      text: 'Unsafe',
      value: 'unsafe',
    },
    {
      text: 'Resolved',
      value: 'resolved',
    },
    {
      text: 'Defects',
      value: 'defects',
    },
  ];
}

const engineLightFilterOptions = () => {
  return [
    { text: 'Check Engine Light', value: 'amberWarningLamp' },
    { text: 'Engine Light', value: 'generalVehicleWarningLight' },
    { text: 'Engine Protect Lamp', value: 'protectWarningLamp' },
    { text: 'MIL Lamp (emissions)', value: 'malfunctionLamp' },
    { text: 'Red Stop Lamp', value: 'redStopLamp' },
  ];
}

export { nextPMFilterOptions, safetyInspectionsFilterOptions, dvirFilterOptions, engineLightFilterOptions };
