import React, { FC } from 'react';
import { Alert } from 'antd';
import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';

const StyledAlert = styled(Alert)`
  && {
    background-color: #f6f9fd;
    border: 1px solid #d9e2ef;
    border-radius: 4px;

    .ant-alert-icon {
      color: #1890ff;
    }

    .ant-alert-message {
      color: black;
      font-weight: bold;
    }

    .ant-alert-description {
      color: black;
    }
  }
`;

// Define props using a TypeScript interface
interface NotificationBannerProps {
  message: React.ReactNode; // Allow strings, elements, or JSX
  onClose?: () => void; // Optional callback function
  hideClose?: boolean; // Optional flag, defaults to false
}

const NotificationBanner: FC<NotificationBannerProps> = ({
  message,
  onClose = () => {},
  hideClose = false,
}) => {
  return (
    <StyledAlert
      message={message}
      type="info"
      icon={<InfoCircleOutlined />}
      showIcon={true}
      closable={!hideClose}
      onClose={onClose}
    />
  );
};

export default NotificationBanner;
